import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";
const queryString = require('query-string');
export class UserStore {    
    isLoading = false;
    userData = [];
    adminsData = [];
    SupportUsersData = [];
    adminData = {};
    customersData = [];
    archivedUsers = [];
    organizationsList = [];
    pageNo=1;
    pageSize=10;
    totalPages = null;
    professionalsDetails = [];
    searchData = [];
    userDelete = [];

    constructor() {
      makeObservable(this, {
        getUsers: action,
        userData: observable,
        getAdminsData: action,
        getSupportUsersData: action,
        getAdminData_Id: action,
        getCustomers: action,
        getArchivedUsers: action,
        getOrganizationsList: action,
        deleteAdminUser: action,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        isLoading: observable,
        adminsData: observable,
        SupportUsersData:observable,
        adminData: observable,
        customersData: observable,
        archivedUsers: observable,
        organizationsList: observable,
        professionalsDetails: observable,
        getProfessionalsDetails: action,
        searchData: observable,
        searchUser: action,
        userDelete: observable
      });
    }

  getUsers(currentPage, currentPageSize, userRole, archived, fields, searchValue, status) {
    this.isLoading = true;
    let url;
    if (searchValue && status == undefined) {
      url = `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&userType=${userRole}&fields=${fields}&archived=${archived}`
    } else if (!searchValue && status != undefined) {
      url = `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&isActive=${status}&userType=${userRole}&fields=${fields}&archived=${archived}`
    } else if (searchValue && status != undefined) {
      url = `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&isActive=${status}&userType=${userRole}&fields=${fields}&archived=${archived}`
    } else {
      url = `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&userType=${userRole}&fields=${fields}&archived=${archived}`
    }
    return io({
      url: url,
      method: "GET",
    })
      .then(
        action(({ data }) => {
          this.isLoading = false;
          this.userData = data?.data;
          this.page = data?.metadata?.page;
          this.totalPages = data?.metadata?.total;
          return data?.data;
        })
      )
      .catch((error) => {
        this.isLoading = false;
        this.userData = []
        this.page = 1;
        this.totalPages = 1;
      });
  }

    getAdminsData(currentPage, currentPageSize) {
        this.isLoading = true;
        return io({
            url: `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&userType=ADMIN&fields=firstName%2CmobileNumber%2CemailAddress%2CisActive%2CuserType%2CcreatedAt%2CupdatedAt&archived=false`,
            method: "GET",
        })
            .then(
                action(({ data }) => {
                    // this.isLoading = false;
                    this.searchData = []
                    this.adminsData = data?.data;
                    this.page = data?.metadata?.page;
                    this.totalPages = data?.metadata?.total;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.adminsData = []
                this.page = 1;
                this.totalPages = 1;
            });
    }
    
    searchUser(currentPage, currentPageSize,searchValue, userRole, archived){   
      console.log(archived)
      this.isLoading = true;
      setTimeout(() =>{
      return io({
          url: `user/search_user?userRole=${userRole}&searchText=${searchValue}&fields=firstName,mobileNumber,emailAddress,userType,isActive,createdAt,updatedAt&archived=${archived}`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.searchData = data?.data;
              this.page = data?.metadata?.page;
              this.totalPages = data?.metadata?.total;
              this.isLoading = false;
          })
      ).catch(
          (error) => {
              this.isLoading = false;
              this.searchData = [];
              this.page = 1;
              this.totalPages = 1;
              const { response: { data = {} } = {} } = error
              message.error(data.message);
            }
      ),500})
    }

    uploadUserImage(fmData, config, onSuccess, onError, setImage_value, file) {
      io({
          url: "file",
          method: "POST",
          data: fmData, 
          config,
      }).then(({ data }) => {
          onSuccess(file);
          setImage_value(data?.url)
      }).catch(err => {
          message.error(err.response.message)
          const error = new Error('Some error');
          onError({event:error});
      })
    }
    getOrganizationsList(loggedin_user_id) {
        this.isLoading = true;
        return io({
          url: `/user/role/organization/${loggedin_user_id}`,
          method: "GET",
        })
          .then(
            action(({data}) => {
              this.isLoading = false;
              this.organizationsList = data;
              return data;
            })
          )
          .catch((error) => {
            this.isLoading = false;
            this.organizationsList = []
          });
    }
    //get Users data in Users/Admin on id
    getAdminData_Id(id) {
        this.isLoading = true;
        return io({
            url: `/user/${id}`,
            method: "GET",
        })
            .then(action(({ data }) => {
                this.isLoading = false;
                this.adminData = data;
                return data;
            })
            )
            .catch((error) => {
                this.isLoading = false;
                this.adminData = {}
            });
    }
    //create or edit Users in Admin
    createUser(fields, isAdd, id, history) {
        io({
            method: isAdd ? "post" : "put",
            url: isAdd ? `/user` : `/user/${id}`,
            data: fields,
        })
            .then(({ res }) => {
                message.success(`Successfully ${isAdd ? "Added" : "Updated"} User`);
                history.goBack()
                // this.getAdminsData()
                // history.push({
                //     pathname: "/Users/Admin",
                // });
            })
            .catch((error) => {   
              console.log(error)          
                const { response: { data = {} } = {} } = error
                message.error(data.message);
            });
    };

    revokeUserToken(user_id) {
      io({
        method: "put",
        url: `/user/revokeToken/${user_id}`,
      })
        .then((data1) => {
          const { data = {} } = data1
          message.success(data?.message);
        })
        .catch((error) => {
          console.log(error);
          const { response: { data = {} } = {} } = error
          message.error(data?.message);
        });
    };
    deleteAdminUser (id) {
        io({
          method: "delete",
          url: `/user/${id}`,
        })
          .then((apiData) => {            
            const { data } = apiData;
            this.userDelete = data;
            message.success(data?.message);
          })
          .catch((error) => {
            console.log(error);
          });
    };
    getSupportUsersData(currentPage, currentPageSize) {
        this.isLoading = true;
        return io({
          url: `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&userType=SUPPORT_ADMIN,ORG_SUPPORT,PRODUCT_ADMIN&fields=firstName%2CmobileNumber%2CemailAddress%2CisActive%2CuserType%2CcreatedAt%2CupdatedAt&archived=false`,
          method: "GET",
        })
          .then(
            action(({data}) => {
              this.isLoading = false;
              this.SupportUsersData = data?.data;
              this.page = data?.metadata?.page;
              this.totalPages = data?.metadata?.total;
              return data?.data;
            })
          )
          .catch((error) => {
            this.isLoading = false;
            this.SupportUsersData = []
            this.page = 1;
            this.totalPages = 1;
          });
    }
    getCustomers(currentPage, currentPageSize) {
        this.isLoading = true;
        return io({
          url: `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&userType=CUSTOMER&fields=firstName,mobileNumber,emailAddress,userType,isActive,createdAt,updatedAt&archived=false`,
          method: "GET",
        })
          .then(
            action(({data}) => {
              this.isLoading = false;
              this.customersData = data?.data;
              this.page = data?.metadata?.page;
              this.totalPages = data?.metadata?.total;
              return data?.data;
            })
          )
          .catch((error) => {
            this.isLoading = false;
            this.customersData = []
            this.page = 1;
            this.totalPages = 1;
          });
    }
    getArchivedUsers(currentPage, currentPageSize) {
        this.isLoading = true;
        return io({
          url: `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=firstName,mobileNumber,emailAddress,userType,isActive,createdAt,updatedAt&archived=true`,
          method: "GET",
        })
          .then(
            action(({data}) => {
              this.isLoading = false;
              this.archivedUsers = data?.data;
              this.page = data?.metadata?.page;
              this.totalPages = data?.metadata?.total;
              return data?.data;
            })
          )
          .catch((error) => {
            this.isLoading = false;
            this.archivedUsers = []
            this.page = 1;
            this.totalPages = 1;
          });
    }

    generateSlots(id, payload) {
      const parsedQueryString = queryString.stringify(payload);
      this.isLoading = true;
      return io({
        url: `/user/${id}/generate_slot?${parsedQueryString}`,
        method: "POST",
      })
        .then(
          action(({data}) => {
            message.success(data.message);
            return data;
          })
        )
        .catch((error) => {
          this.isLoading = false;
          const { response: { data = {} } = {} } = error
          message.error(data.message);
          return data;
        });
  }

  addMyAvailability(id, my_availability, slot_duration) {
    this.isLoading = true;
    return io({
      url: `/user/${id}`,
      method: "PUT",
      data: {
        my_availability: my_availability,
        slot_duration: slot_duration,
        _id: id
      }
    })
      .then(
        action(({data}) => {
          message.success(`Successfully updated availability data`);
          this.adminData = data;
          return data;
        })
      )
      .catch((error) => {
        this.isLoading = false;
        const { response: { data = {} } = {} } = error
        message.error(data.message);
        return data;
      });
}

resetSlots(id) {
  this.isLoading = true;
  return io({
    url: `/user/${id}/slot_reset`,
    method: "POST",
  
  })
    .then(
      action(({data}) => {
        message.success(data.message);
      })
    )
    .catch((error) => {
      this.isLoading = false;
      const { response: { data = {} } = {} } = error
      message.error(data.message);
    });
}

getProfessionalsDetails(currentPage, currentPageSize){
  return io({
    url: `/user?pageNo=${currentPage}&pageSize=${currentPageSize}&userType=PROFESSIONAL&firstName,emailAddress,mobileNumber,updatedAt&archived=false`,
    method: "GET"
  })
  .then(
    action(({data}) => {
      this.professionalsDetails = data
    })
  ).catch((error) => {
    const { response: { data = {} } = {} } = error
    message.error(data.message);
  })
}

}