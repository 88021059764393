import { message } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class TermsConditionsStore {
  termsConditions = [];
  isLoading = false;
  pageNo= 1;
  pageSize=10;
  totalPages = null;

  constructor() {
    makeObservable(this, {
      getTermsConditions: action,
      termsConditions: observable,
      isLoading: observable,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      upDateStatus:action,
      createTermsCondition:action,
      deleteRecord: action,
    });
  }

   getTermsConditions(currentPage, currentPageSize) {
    this.isLoading = true;
    return io({
      url: `/term_condition?pageNo=${currentPage}&pageSize=${currentPageSize}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.termsConditions = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.termsConditions = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  createTermsCondition(fields, isAdd, recordId, history){
    return io({
      method: isAdd ? "POST" : "PUT",
      url : isAdd ? `/term_condition`: `/term_condition/${recordId}`,
      data: fields,
    })
      .then(
        action((data) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} terms and condition`);
        history.push("/terms-conditions")
      })
      )
      .catch((error) => {
        const { response: { data = {} } = {} } = error;
        message.error(error?.message);
        return data;
      });
  };

  deleteRecord(id) {
    return io({
      method: "DELETE",
      url: `/term_condition/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getTermsConditions(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        message.error(error?.message);
      });
  };

  upDateStatus(id, shortName, status) {
    return io({
      method: "PUT",
      url :  `term_condition/status/${id}?shortName=${shortName}&isActive=${status}`,
    })
      .then(( res ) => {
        message.success(`Successfully updated status`);
        this.getTermsConditions(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error;
        message.error(data?.message);
      });
  };

}
