import React from 'react';
import { ErrorMessage } from "formik";


function ErrorMessageComponent({name}) {
    return (
        <ErrorMessage
        name={name}
        component="div"
        className="invalid-feedback"
      />
    )
}

export default ErrorMessageComponent
