import { CategoryStore } from "./CategoryStore";
import { CommonImagesStore } from "./CommonImagesStore";
import { LoginStore } from "./LoginStore";
import { OrganizationTypesStore } from "./OrganizationTypesStore";
import { ProfessionalStore } from "./ProfessionalStore";
import { UserStore } from "./UserStore";
import { OperatingLocationStore } from "./OperatingLocationStore";
import { OrganizationAttributesStore } from "./organizationAttributesStore";
import { ContactsStore } from "./ContactsStore";
import { TCMappingStore } from "./TCMappingStore";
import { ReviewAttributesStore } from "./ReviewAttributesStore";
import { ServiceStore } from "./ServiceStore";
import { ServicesStore } from "./ServicesStore";
import { TermsConditionsStore } from "./TermsConditionsStore";
import { ServiceLocationsStore } from "./ServiceLocationsStore";
import { ProductsStore } from "./ProductsStore";
import { ServiceMappingStore } from "./ServiceMappingStore";
import { OrdersStore } from "./OrdersStore";
import { QuestionsStore } from "./QuestionsStore"
import { RefundsStore } from "./RefundsStore";
import { OrganizationsStore } from "./OrganizationsStore";
import { CaseSheetsStore } from "./CaseSheetsStore";
import { TenantsStore } from "./TenantsStore";
import { ArticleStore } from "./ArticleStore";
import { InvestigationStore } from "./InvestigationStore";
import { CustomerReviewsStore } from "./CustomerReviewsStore";
import { VoucherStore } from "./VoucherStore";
import { PricingsStore } from "./PricingsStore";
import { HealthCardsStore } from "./HealthCardsStore";
import { LabRequestsStore } from "./LabRequestsStore";
export class RootStore {
    loginStore;
    categoryStore;
    professionalStore;
    userStore;
    commonImagesStore;
    operatingLocationStore;
    organizationTypesStore
    contactsStore;
    organizationAttributesStore;
    tcMappingsStore;
    reviewAttributesStore;
    serviceStore;
    servicesStore
    termsConditionsStore;
    serviceLocationsStore
    productsStore;
    serviceMappingStore;
    ordersStore;
    questionsStore;
    refundsStore;
    organizationsStore;
    caseSheetsStore;
    tenantsStore;
    articleStore;
    investigationssStore;
    customerReviewsStore;
    voucherStore
    pricingsStore;
    healthCardsStore;
    labRequestsStore;
  
    constructor() {
      this.loginStore = new LoginStore(this);
      this.categoryStore = new CategoryStore(this);
      this.professionalStore = new ProfessionalStore(this);
      this.userStore = new UserStore(this);
      this.commonImagesStore = new CommonImagesStore(this);
      this.operatingLocationStore = new OperatingLocationStore(this);
      this.organizationTypesStore = new OrganizationTypesStore(this);
      this.contactsStore = new ContactsStore(this);
      this.organizationAttributesStore = new OrganizationAttributesStore(this);
      this.tcMappingsStore = new TCMappingStore(this);
      this.reviewAttributesStore = new ReviewAttributesStore(this);
      this.serviceStore = new ServiceStore(this);
      this.ServiceStore = new ServiceStore(this);
      this.termsConditionsStore = new TermsConditionsStore(this);
      this.servicesStore = new ServicesStore(this);
      this.serviceLocationsStore = new ServiceLocationsStore(this);
      this.productsStore = new ProductsStore(this);
      this.serviceMappingStore = new ServiceMappingStore(this);
      this.ordersStore = new OrdersStore(this);
      this.questionsStore = new QuestionsStore(this);
      this.refundsStore = new RefundsStore(this);
      this.organizationsStore = new OrganizationsStore(this);
      this.caseSheetsStore = new CaseSheetsStore(this);
      this.tenantsStore = new TenantsStore(this);
      this.articleStore = new ArticleStore(this);
      this.investigationssStore = new InvestigationStore(this);
      this.customerReviewsStore = new CustomerReviewsStore(this);
      this.voucherStore = new VoucherStore(this);
      this.pricingsStore = new PricingsStore(this);
      this.healthCardsStore = new HealthCardsStore(this);
      this.labRequestsStore = new LabRequestsStore(this);
    }

  }


