import * as yup from "yup";

export const ProductsValidationSchema = yup.object({
  product_name: yup.string().required("required"),
  timeToComplete: yup
    .string()
    .matches("^[0-9]{1,3}$", "Shoud not be more than 3 digits"),
  product_price: yup
    .string()
    .matches("^[0-9]{1,6}$", "Shoud not be more than 6 digits"),
});

export const FaqsValidationSchema = yup.object({
  stepNo: yup.string().required("required"),
  shortDescription: yup.string().max(256, "Short Description is too long(Limit to 256) ").required("required"),
  longDescription: yup.string().required("required")
});

export const MetaDataValidationSchema = yup.object({
  name: yup.string().required("required"),
  content: yup.string().required("required").nullable(),
});
