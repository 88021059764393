import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class OrganizationTypesStore {
  organizationTypes = [];
  isLoading = true;

  constructor() {
    makeObservable(this, {
      getOrganizationTypes: action,
      organizationTypes: observable,
      isLoading: observable,
      deleteRecord:action,
    });
  }

  getOrganizationTypes() {
    return io({
      url: `/organizationtypes`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.organizationTypes = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.organizationTypes = [];
      });
  }

  deleteRecord = (id) => {
    io({
      method: "delete",
      url: `/organizationtypes/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getOrganizationTypes();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  createOrganizationType(fields, isAdd, recordId, closeModal){
    io({
      method: isAdd ? "post" : "put",
      url : isAdd ? `/organizationtypes`: `/organizationtypes/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} Organization Type`);
        this.getOrganizationTypes();
        closeModal();
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          message: error?.message,
          description: data?.errmsg,
          duration: 5,
        });
      });
  };


}
