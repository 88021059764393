import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class InvestigationStore {
  investigations = [];
  isLoading = false;
  investigationDetials = "";
  pageNo= 1;
  pageSize=10;
  totalPages = null;
  healthGroups = [];
  historyGroupDetails = "";
  diagnostics = [];
  consultations = [];

  constructor() {
    makeObservable(this, {
      getInvestingations: action,
      createInvestigation: action,
      investigations: observable,
      getInvestingations_id: action,
      investigationDetials: observable,
      deleteRecord: action,
      isLoading: observable,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      getHealthCards: action,
      healthGroups: observable,
      getHistoryGroup_id: action,
      historyGroupDetails: observable,
      createHealthGroup: action,
      deleteHealthGroup: action,
      getDiagnostics:action,
      diagnostics: observable,
      consultations: observable,
      getConsultations: action,
      filterDiagnostics: action,
    });
  }

   getInvestingations(currentPage, currentPageSize, searchText) {
    this.isLoading = true;
    return io({
      url: `/diagnostics/investigations?pageNo=${currentPage}&pageSize=${currentPageSize}${searchText}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.investigations = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.investigations = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  
  createInvestigation(fields, isAdd, recordId, history, name){
    io({
      method: isAdd ? "POST" : "PUT",
      url : `/diagnostics/investigations/${ !isAdd ? recordId : ""}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${isAdd ? "Added" : "updated"} ${name}`);
        history.push("/investigations");
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.errmsg,
          duration: 5,
        });
      });
  };
  
  getInvestingations_id(investigationID) {
    return io({
      url: `/diagnostics/investigations/${investigationID}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.investigationDetials = data;
        return data;
      })
      .catch((error) => {
        this.investigationDetials = [];
      });
  }

  deleteRecord(investigationID){
    io({
      method: "DELETE",
      url : `/diagnostics/investigations/${investigationID}`,
    })
      .then(({ data }) => {
        message.success(data?.message);
        this.getInvestingations(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

  getInvestingationByType(type, currentPage, currentPageSize) {
    this.isLoading = true;
    return io({
      url: `/diagnostics/investigations/type/${type}?pageNo=${currentPage}&pageSize=${currentPageSize}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.investigations = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.investigations = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

// =============== Health Groups ==============

  getHealthCards(currentPage, currentPageSize, searchValue, status) {
    this.isLoading = true;    
    let url;
    if(searchValue && status == undefined){
      url = `/healthgroups?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}`
    }else if(!searchValue && status != undefined){
      url = `/healthgroups?pageNo=${currentPage}&pageSize=${currentPageSize}&isActive=${status}`
    }else if(searchValue && status != undefined){
      url = `/healthgroups?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&isActive=${status}`
    }else{
      url = `/healthgroups?pageNo=${currentPage}&pageSize=${currentPageSize}`
    }
    return io({
      url: url,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.healthGroups = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch(() => {
        this.isLoading = false;
        this.healthGroups = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  getHistoryGroup_id(historyGroup_id) {
    return io({
      url: `/healthgroups/${historyGroup_id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.historyGroupDetails = data;
        return data;
      })
      .catch(() => {
        this.historyGroupDetails = [];
      });
  }

  createHealthGroup(fields, isAdd, recordId, history, name){
    return io({
      method: isAdd ? "POST" : "PUT",
      url : `/healthgroups/${ !isAdd ? recordId : ""}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${isAdd ? "Added" : "updated"} ${name}`);
        history.push("/health-groups");
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

  deleteHealthGroup(healthGroup_id){
    io({
      method: "DELETE",
      url : `/healthgroups/${healthGroup_id}`,
    })
      .then(({ data }) => {
        message.success(data?.message);
        this.getHealthCards(1, this.pageSize);
        this.pageNo = 1;
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

// ================== Diagnostics ====================

getDiagnostics(currentPage, currentPageSize, searchText) {
  this.isLoading = true;
  return io({
    url: `/diagnostics?pageNo=${currentPage}&pageSize=${currentPageSize}${searchText}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = false;
      this.diagnostics = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch(() => {
      this.isLoading = false;
      this.diagnostics = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

getDiagnostic_id(diagnostic_id) {
  return io({
    url: `/diagnostics/${diagnostic_id}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.historyGroupDetails = data;
      return data;
    })
    .catch(() => {
      this.historyGroupDetails = [];
    });
}

filterDiagnostics(currentPage, currentPageSize, filter, searchText) {
  this.isLoading = true;
  return io({
    url: `/diagnostics?${filter}&pageNo=${currentPage}&pageSize=${currentPageSize}${searchText}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = false;
      this.diagnostics = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch(() => {
      this.isLoading = false;
      this.diagnostics = [];
      this.page = 1;
      this.totalPages = 1;
    });
}


// ================== Diagnostics ====================

getConsultations(currentPage, currentPageSize, searchText) {
  this.isLoading = true;
  return io({
    url: `/consultation?pageNo=${currentPage}&pageSize=${currentPageSize}${searchText}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = false;
      this.consultations = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch(() => {
      this.isLoading = false;
      this.consultations = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

}
