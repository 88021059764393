import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class RefundsStore {
  refunds = [];
  isLoading = true;
  pageNo= 1;
  pageSize=10;
  totalPages = null;

  constructor() {
    makeObservable(this, {
      getRefunds: action,
      isLoading: observable,
      deleteRecord:action,
      createRefund: action,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      refunds: observable,  
    });
  }

getRefunds(currentPage, currentPageSize, searchText) {
    this.isLoading = false;
    return io({
      url: `/api/refunds?pageNo=${currentPage}&pageSize=${currentPageSize}${searchText}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.refunds = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.refunds = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  deleteRecord = (id) => {
    io({
      method: "DELETE",
      url: `/api/refunds/${id}`,
    })
      .then(({data}) => {
        message.success(data?.message);
        console.log(data?.message);
        this.getRefunds(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createRefund(fields, isAdd, history, handleClose, recordId){
    io({
      method: isAdd ? "POST" : "PUT",
      url : `/refunds/${ !isAdd ? recordId : ""}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully updated refund`);
        history.push("/refunds");
        handleClose();
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };


}
