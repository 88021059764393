import { Button, Row } from "antd";
import React from "react";
import Wyseplus_Logo from "../assets/Wyseplus_Logo.png";
import { useLocation } from "react-router";
import PopOver from "./LoggedInUserPopup";
import { useAuthDetails } from "../context/AuthContext";

const HeaderComponent = (props) => {
  const location = useLocation();

  const { logout } = useAuthDetails();

  const handleLogout = () => {
    logout();
  }

  return (
    <header className="header"
    style={{ 
      position: 'fixed',
      top: 0
    }}>
      { location.pathname !== "/" ?
        <Button type="text" className="toggle" shape="circle" size="middle">
          {props.icon}
        </Button> : null
      }
      <Row style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", padding: "10px 0 0 15px",  }}>
          <img src={Wyseplus_Logo} alt="wyseplus-admin" width="auto" height="30px" />
        </div>
        <PopOver logout={handleLogout}/>
      </Row>
    </header>
  );
}

export default HeaderComponent;
