import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
const queryString = require('query-string');
export class HealthCardsStore {
  healthNetworks = [];
  isLoading = false;
  pageNo= 1;
  pageSize=10;
  totalPages = null;
  healthNetworkDetails = "";
  organizations = [];
  servicesList = [];
  healthCards = [];
  healthCardDetails = "";
  categoryNameFilter = []
  constructor() {
    makeObservable(this, {
      getHealthNetworks: action,
      healthNetworks: observable,
      isLoading: observable,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      getHealthNetwork_id:action,
      healthNetworkDetails: observable,
      createHealthNetwork: action,
      organizations: observable,
      getOrganizations: action,
      servicesList : observable,
      getServicesList: action,
      getHealthCard: action,
      healthCards: observable,
      healthCardDetails: observable,
      getHealthCard_id: action,
      categoryNameFilter: observable,
      getHealthNetworks_forFilter: action
    });
  }

   getHealthNetworks(currentPage, currentPageSize, categoryType, categoryName) {
    this.isLoading = true;
    let url;
    if(categoryType !=undefined && categoryName == undefined){
      url = `/healthcardcategory?pageNo=${currentPage}&pageSize=${currentPageSize}&categoryType=${categoryType}`
    }else if(categoryType == undefined && categoryName != undefined) {
      url = `/healthcardcategory?pageNo=${currentPage}&pageSize=${currentPageSize}&categoryName=${categoryName}`
    }else if(categoryType != undefined && categoryName != undefined) {
      url = `/healthcardcategory?pageNo=${currentPage}&pageSize=${currentPageSize}&categoryType=${categoryType}&categoryName=${categoryName}`
    }else{
      url = `/healthcardcategory?pageNo=${currentPage}&pageSize=${currentPageSize}`
    }
    return io({
      url: url,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.healthNetworks = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.healthNetworks = [];
        this.page = 1;
        this.totalPages = 1;
        const { response: { data = {} } = {} } = error
        message.error(data?.message,5);
      });
  }

  getHealthNetworks_forFilter() {
    this.isLoading = true;
    return io({
      url: `/healthcardcategory?fields=categoryName`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.categoryNameFilter = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.categoryNameFilter = [];
      });
  }

  getHealthNetwork_id(healthNetwork_id) {
    return io({
      url: `/healthcardcategory/${healthNetwork_id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.healthNetworkDetails = data;
        return data;
      })
      .catch(() => {
        this.healthNetworkDetails = [];
      });
  }

  createHealthNetwork(fields, isAdd, recordId, history, name){
    return io({
      method: isAdd ? "POST" : "PUT",
      url : `/healthcardcategory/${ !isAdd ? recordId : ""}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${isAdd ? "Added" : "updated"} ${name}`);
        history.push("/health-card-categories");
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.errmsg,
          duration: 5,
        });
      });
  };

  deleteRecord(healthNetwork_id){
    io({
      method: "DELETE",
      url : `/healthcardcategory/${healthNetwork_id}`,
    })
      .then(({ data }) => {
        message.success(data?.message);
        this.getHealthNetworks(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

  
getOrganizations(fields) {
  return io({
    url: `/organizations?fields=${fields}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.organizations = data?.data;
      return data?.data;
    })
    .catch((error) => {
      this.organizations = [];
    });
}

getServicesList(fields) {
  return io({
      url: `/service?fields=${fields}`,
      method: "GET",
  })
      .then(
          action(({ data }) => {                    
              this.servicesList = data?.data;
              return data?.data;
          })
      )
      .catch((error) => {
          this.servicesList = [];
      });
}

// ========== Health Card ==========

getHealthCard(currentPage, currentPageSize, searchValue, status) {
  this.isLoading = true;
  let url;
  if(searchValue && status == undefined){
    url = `/healthcard?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}`
  }else if(!searchValue && status != undefined){
    url = `/healthcard?pageNo=${currentPage}&pageSize=${currentPageSize}&isActive=${status}`
  }else if(searchValue && status != undefined){
    url = `/healthcard?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&isActive=${status}`
  }else{
    url = `/healthcard?pageNo=${currentPage}&pageSize=${currentPageSize}`
  }
  return io({
    url: url,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = false;
      this.healthCards = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch((error) => {
      this.isLoading = false;
      this.healthCards = [];
      this.page = 1;
      this.totalPages = 1;
      const { response: { data = {} } = {} } = error
      message.error(data?.message,5);
    });
}

getHealthCard_id(healthCard_id) {
  return io({
    url: `/healthcard/${healthCard_id}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.healthCardDetails = data;
      return data;
    })
    .catch(() => {
      this.healthCardDetails = [];
    });
}

createHealthCard(fields, isAdd, recordId, cardId, history, name){
  return io({
    method: isAdd ? "POST" : "PUT",
    url : isAdd ? `/healthcard` : `/healthcard/${cardId}`,
    data: fields,
  })
    .then(({ res }) => {
      message.success(`Successfully ${isAdd ? "Added" : "updated"} ${name}`);
      history.push("/health-card");
    })
    .catch((error) => {
      const { response: { data = {} } = {} } = error
      notification["error"]({
        description: data?.message,
        duration: 5,
      });
    });
};

deleteHealthCard(healthNetwork_id){
  io({
    method: "DELETE",
    url : `/healthcard/${healthNetwork_id}`,
  })
    .then(({ data }) => {
      message.success(data?.message);
      this.getHealthCard(this.pageNo, this.pageSize);
    })
    .catch((error) => {
      const { response: { data = {} } = {} } = error
      notification["error"]({
        description: data?.message,
        duration: 5,
      });
    });
};

}
