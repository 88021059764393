import React from "react";
import "./App.scss";
import {RootStoreProvider} from "./providers/RootStoreProvider";
import AuthProvider from "./context/AuthContext";
import LayoutComponent from "./Pages/nav/Layout";

function App() {
    return(
        <RootStoreProvider>
             <AuthProvider>
                <LayoutComponent />
             </AuthProvider>
        </RootStoreProvider>
    );
}

export default App;