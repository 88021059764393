import axios from "axios";
import instances from "../api/instances.json";
import { getAccessToken, setAccessToken } from "../context/AuthContext";
import { isClientSide } from "../utils/utils";
import Cookies from "js-cookie";

export const getBaseUrl = () => {
  // Configure api urls based on APP_ENV
  // Window object is not available on server side
  const { apiURL } = instances[process.env.REACT_APP_AUTHDOMAIN];
  return apiURL;
};

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getAccessToken();
    config.headers["X_TENANT_ID"] = Cookies.get("x_tenant_id");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const user_id = isClientSide ? localStorage.getItem("user_id") : "";
    if (error.response.status === 401) {
      if (
        !error.config.url.startsWith("/refreshToken/") &&
        isClientSide &&
        localStorage.getItem("auth") === "true"
      ) {
        const originalRequest = error.config;
        io({ method: "post", url: `/refreshToken/${user_id}` })
          .then(({ data = {} } = {}) => {
            const { accessToken } = data;
            setAccessToken(accessToken);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + accessToken;
            return axios(originalRequest);
          })
          .catch(() => {
            setAccessToken("");
            alert("Your session has timed out. Please login again.");
            window.location = "/login";
            return Promise.reject(error);
          });
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

axios.defaults.baseURL = `${getBaseUrl()}/iclap-api/`;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Access-Control-Allow-Headers"] = "*"; //
axios.defaults.withCredentials = true;

export const io = async ({ url, method, data, params, headers }) => {
  // refer here : https://www.npmjs.com/package/axios#axiosrequestconfig-1

  return await axios({
    url,
    method,
    headers,
    params,
    data,
  });
};
