import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class OrdersStore {
  orders = [];
  isLoading = true;
  pageNo= 1;
  pageSize=10;
  totalPages = null;
  orderDetails = "";
  fallowUps = [];
  followupDetails = "";
  consultationDetails = "";
  payments = [];
  healthCardOrder = "";

  constructor() {
    makeObservable(this, {
      getOrders: action,
      orders: observable,
      isLoading: observable,
      deleteRecord:action,
      createOrder: action,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      orderDetails: observable,
      getOrderDetails:action,
      getFallowUps: action,
      getFallowUpById: action,
      getConsultationDetails: action,
      sendSms: action,
      payments: observable,
      getPayments: action,
      getHealthCardOrder: action,
      healthCardOrder: observable,
      searchOrdersByStatus: action,
      searchOrder: action,
      createHealthCard: action,
      fallowUps: observable
    });
  }

  getOrders(currentPage, currentPageSize, type) {
    this.isLoading = false;
    let orderType = type === undefined ? "" : type;
    return io({
      url: `/order?pageNo=${currentPage}&pageSize=${currentPageSize}${orderType}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.orders = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.orders = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  getOrderDetails(order_id) {
     let user_id = localStorage.getItem("user_id");
    return io({
      url: `/order/${user_id}/${order_id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.orderDetails = data;
        return data;
      })
      .catch((error) => {
        this.orderDetails = [];
      });
  }


  deleteRecord = (id) => {
    return io({
      method: "DELETE",
      url: `/order/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getOrders(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  createOrder(fields, recordId, history, name){
    io({
      method: "PUT",
      url : `/order/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully updated ${name}`);
        history.push("/orders");
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

  searchOrdersByStatus(currentPage, currentPageSize, orderStatus) {
    this.isLoading = false;
    return io({
      url: `/order?${orderStatus}&pageNo=${currentPage}&pageSize=${currentPageSize}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.orders = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.orders = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  searchOrder(currentPage, currentPageSize, searchValue) {
    this.isLoading = false;
    return io({
      url: `/search/order?searchText=${searchValue}&pageNo=${currentPage}&pageSize=${currentPageSize}&fields=operating_location_name,payment_status,order_status,organization_id,customer_firstName,customer_lastName,order_lines,createdAt,total_quantity,total_price,order_date,archived`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.orders = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.orders = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }
// ========================

  getProductDetailsById(id) {
    return io({
      url: `/order/${id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.orderDetails = data;
        return data?.data;
      })
      .catch((error) => {
        this.orderDetails = [];
      });
  }

  getFallowUps(currentPage, currentPageSize, searchValue) {
    this.isLoading = false;
    return io({
      url: searchValue ? `/followup?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}` : `/followup?pageNo=${currentPage}&pageSize=${currentPageSize}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.fallowUps = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.fallowUps = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  getFallowUpById(id) {
    return io({
      url: `/followup/${id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.followupDetails = data;
        return data;
      })
      .catch((error) => {
        this.fallowUps = [];
      });
  }

  getConsultationDetails(id) {
    return io({
      url: `/consultation/${id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.consultationDetails = data;
        return data;
      })
      .catch((error) => {
        this.consultationDetails = [];
      });
  }

  sendSms(fields, closeModal) {
    return io({
      method: "POST",
      url : "/send/bulk/sms",
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully sent sms`);
        closeModal();
      })
      .catch(() => {
        this.isLoading = true;
      });
  };

// ============ Payments ==================
getPayments(currentPage, currentPageSize, searchValue) {
  this.isLoading = false;
  return io({
    url: searchValue ? `/api/payments?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}` : `/api/payments?pageNo=${currentPage}&pageSize=${currentPageSize}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = true;
      this.payments = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch((error) => {
      this.isLoading = true;
      this.payments = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

//  =========== health card order ==================

getHealthCardOrder(id) {
  return io({
    url: `/healthcardcategory/${id}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.healthCardOrder = data;
      return data;
    })
    .catch((error) => {
      this.healthCardOrder = [];
    });
}

createHealthCard(fields, closeModal) {
  return io({
    method: "POST",
    url : "/healthcard",
    data: fields,
  })
    .then(({ res }) => {
      message.success(`Successfully Created HealthCard`);
      closeModal();
    })
    .catch(() => {
      this.isLoading = true;
    });
};

searchHealthCardOrder(currentPage, currentPageSize, searchValue) {
  this.isLoading = false;
  return io({
    url: `/search/order?order_type=HEALTHCARD&searchText=${searchValue}&pageNo=${currentPage}&pageSize=${currentPageSize}&fields=operating_location_name,payment_status,order_status,organization_id,customer_firstName,customer_lastName,order_lines,createdAt,total_quantity,total_price,archived`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = true;
      this.orders = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch((error) => {
      this.isLoading = true;
      this.orders = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

searchHealthCardOrdersByStatus(currentPage, currentPageSize, orderStatus) {
  this.isLoading = false;
  return io({
    url: `/order?order_type=HEALTHCARD&${orderStatus}&pageNo=${currentPage}&pageSize=${currentPageSize}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = true;
      this.orders = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch((error) => {
      this.isLoading = true;
      this.orders = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

}
