import React, { useState } from "react";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Divider, Popover, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import Cookies from "js-cookie";

const LoggedInUserPopup = (props) => {

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const content = (
    <div>
      <Card
        bordered={false}
        style={{ width: 240 }}
        cover={
          <Row align="center">
            <Avatar icon={<UserOutlined />} />
          </Row>
        }
      >
        <Row align="center">
          <Meta title={localStorage.getItem("user_name")} />
        </Row>
      </Card>
      <Row style={{ marginTop: "-5px"}} align="center"><h4>Tenant : {Cookies.get("x_tenant_id")}</h4></Row>
      <Divider style={{ margin: "10px" }} />
      <Row align="center">
        <Button
          type="primary"
          ghost
          icon={<LogoutOutlined />}
          onClick={() => props.logout()}
        >
          Logout
        </Button>
      </Row>
    </div>
  );

  return (
    <Popover
      visible={isPopupVisible}
      onClick={() => setIsPopupVisible(true)}
      trigger="click"
      content={content}
      placement="bottomRight"
      style={{ width: 300 }}
      arrowPointAtCenter={false}
      onVisibleChange={(e) => setIsPopupVisible(e)}
    >
      <Avatar   
        style={{ 
          width: "40px", 
          height: "40px", 
          margin: "5px 10px 0 0", 
          backgroundColor: "#ddd", 
          color: "#fff",
          border: "1px solid #bbb", 
          fontSize:"20px", 
          padding:"-5px 0 0 0", 
          textAlign:"center"}} 
        size={35} icon={<UserOutlined />} />
    </Popover>
  );
}

export default LoggedInUserPopup;
