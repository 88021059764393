import { observable, action, makeObservable } from "mobx";
import { getBaseUrl, io } from "../api/io";
import { message } from "antd";
import { observer } from "mobx-react-lite";
import axios from "axios";
import Cookies from "js-cookie";

export class VoucherStore {    
    isLoading = false;
    voucherList = [];
    pageNo= 1;
    pageSize=10;
    totalPages = null;
    voucherDataonId = {};
    constructor() {
      makeObservable(this, {
        isLoading: observable,
        voucherList:observable,
        getVoucherList:action,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        voucherDataonId: observable,
        getVoucher_BasedOnVoucherId: action,
        deleteVoucher: action,
        searchVoucher: action
      });
    }
    getVoucherList(currentPage, currentPageSize,url){   
        this.isLoading = true;
        return io({
            url: `voucher?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=voucherNumber,groupCode,beneficiaryName,generatedOn,validFrom,validTo,points,usedOnDate,enable,speciality,updatedAt${url}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.voucherList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            (error) => {
                this.isLoading = false;
                this.voucherList = [];
                this.page = 1;
                this.totalPages = 1;
                const { response: { data = {} } = {} } = error
                message.error(`${data?.message}`,5);
              }
        )
    }

    searchVoucher(currentPage, currentPageSize, searchValue){   
      this.isLoading = true;
      return io({
          url: `search/voucher?searchText=${searchValue}&pageNo=${currentPage}&pageSize=${currentPageSize}&fields=voucherNumber,groupCode,beneficiaryName,generatedOn,validFrom,validTo,points,usedOnDate,enable,speciality,updatedAt`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.voucherList= data?.data;
              this.page = data?.metadata?.page;
              this.totalPages = data?.metadata?.total;
              this.isLoading = false;
          })
      ).catch(
          () => {
              this.isLoading = false;
              this.voucherList = [];
              this.page = 1;
              this.totalPages = 1;
            }
      )
    }

    getVoucher_BasedOnVoucherId =(voucher_id) => {
      this.isLoading = true;
      return io({
          url: `/voucher/${voucher_id}`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.voucherDataonId = data
              this.isLoading = false;
              return data;
          })
      ).catch(
          () => {
              this.isLoading = false;
              this.voucherDataonId = {};
            }
      )
    }
    updateVoucher(fields, voucher_id){
      io({
        method: "put",
        url : `/voucher/${voucher_id}`,
        data: fields,
      })
        .then(({ res }) => {
          message.success(`Successfully Updated Voucher`);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    deleteVoucher = (voucher_id) => {
        io({
          method: "delete",
          url: `/voucher/${voucher_id}`,
        })
          .then((data) => {
            message.success("Deleted successfully");
            this.getVoucherList(1,10)
          })
          .catch((error) => {
            console.log(error);
          });
    };

    uploadCSV(fields,history) {
      
      let fmData = new FormData();

      const config = {
        headers: { X_TENANT_ID: Cookies.get("x_tenant_id") },
      };

      fmData.append("image", fields.image);
      fmData.append("groupCode", fields.groupCode);
      fmData.append("groupDescription", fields.groupDescription);

      axios
        .post(`${getBaseUrl()}/iclap-api/file/upload/csv_voucher`, fmData, config)
        .then((res) => {
          message.success(res.data.message);
          history.push("/vouchers");
        })
        .catch((err) => {
          console.error(err)
          message.error('Upload Failed' , 3);
          // const error = new Error('Some error');
        });
    };


}


