
const parseUrl = require("parse-url");

export const isClientSide = typeof window !== "undefined";

export const getTenantCode = (url) => {
  const { resource } = parseUrl(url);
  const data = resource.split(".");
  return data[0];
};
