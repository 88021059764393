import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class LoginStore {
    isLoading = false;
    tenantList = [];
    isLogInSuccess = false;

    constructor() {
      makeObservable(this, {
        getTenantList: action,
        loginUser: action,
        isLoading: observable,
        tenantList: observable,
        isLogInSuccess: observable
      });
    }

    getTenantList() {
        this.isLoading = true;
        return io({
          url: `/tenant/tenantList?isActive=true&archived=true`,
          method: "GET",
        })
          .then(
            action(({data}) => {
              this.isLoading = false;
              this.tenantList = data?.data;
              return data?.data;
            })
          )
          .catch((error) => {
            this.isLoading = false;
            this.tenantList = []
          });
      }


      loginUser(payload){
        this.isLogInSuccess = true
        return io({
          url: `/user/admin_console_login`,
          method: "POST",
          data: payload
        })
          .then(
            action(({data}) => {
              this.isLogInSuccess = true;
              return data;
            })
          )
          .catch((error) => {
            this.isLogInSuccess = true;
            const { response: { data = {} } = {} } = error
            return data;
          });
      }
}
