import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, Select, Row, Col, Spin,Tooltip } from "antd";
import { Formik, ErrorMessage } from "formik";
import { useHistory } from "react-router";
import Wyseplus_Logo from "../../assets/Wyseplus_Logo.png";
// import wyseplus from "../../assets/wyseplus.png";
import { useLoginStore } from "../../providers/RootStoreProvider";
import Cookies from "js-cookie";
import { useAuthDetails } from "../../context/AuthContext";
import { LoadingOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { observer } from "mobx-react-lite";
import { Alert } from 'antd';
import { LoginSchema } from "./userValidationSchema";

const { Option } = Select;

const Login = observer(() => {
  const history = useHistory();
  const loginStore = useLoginStore();
  const { login } = useAuthDetails();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { isLoading } = loginStore;
  const [ isError, handleIsError ] = useState({ flagged: false, message: "" });

  const { tenantList } = loginStore;

  const handleLogin = (fields) => {
    loginStore.loginUser(fields).then((res) => {
      if (res?.statusCode === 200) {
        login(res);
        let roles = JSON.parse(localStorage.getItem('roles'))
        if (roles.includes("SUPER_ADMIN") || roles.includes("ADMIN")) {
          history.push({ pathname: "/users/admin" });
        } else if (roles.includes("PRODUCT_ADMIN")) {
          history.push({ pathname: "/categories" });
        } else if (roles.includes("SUPPORT_ADMIN")) {
          history.push({ pathname: "/users/support-users" });
        } else if (roles.includes("ORG_ADMIN")) {
          history.push({ pathname: "/org-users" });
        } else if (roles.includes("ORG_SUPERVISOR")) {
          history.push({ pathname: "/org-users" });
        } else if (roles.includes("ORG_SUPPORT")) {
          history.push({ pathname: "/org-users" });
        } else if (roles.includes("ORG_AGENT")) {
          history.push({ pathname: "/org-users" });
        } else if (roles.includes("ORG_TECH")) {
          history.push({ pathname: "/org-users" });
        }
        
        handleIsError({ flagged: false });
      }
      else {
        handleIsError({ flagged: true, message: res.message })
      }
    });
  };


  useEffect(() => {
    loginStore.getTenantList();
  }, [loginStore])


  return (
    <>
      {isLoading ? <Spin indicator={antIcon} /> : (
        <div
          className="login-set"
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "140px"
          }}
        >
          <Formik
            initialValues={{
              emailAddress: "",
              password: "",
              tenant: "" || undefined
            }}
            validationSchema={LoginSchema}
            onSubmit={(fields) => {
              const { tenant, ...rest} = fields
              Cookies.set("x_tenant_id", tenant)
              handleLogin(rest);
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
              <Form
                layout="vertical"
                className="content-edit"
                onFinish={handleSubmit}
                style={{ width: "450px", verticalAlign: "center" }}
              >
                <Row style={{ justifyContent: "space-between" }}>
                  <Col span={12}><h2>Login</h2></Col>
                  <Col span={12} push={5}><img src={Wyseplus_Logo} alt="wyse-admin" width="auto" height="27px" /></Col>
                </Row>
                <Divider />
                {isError.flagged && <Alert closable onClose={() => handleIsError(false)} message={isError.message} type="error" />}
                <Form.Item label="Email" required>
                  <Input
                    allowClear 
                    name="emailAddress"
                    value={values.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={"form-control" + (errors.emailAddress && touched.emailAddress ? "is-invalid" : "")}
                    placeholder="Enter email address"
                  />
                  <ErrorMessage name="emailAddress" component="div" className="invalid-feedback" />
                </Form.Item>
                <Form.Item label="Password" htmlFor="password" required>
                  <Input.Password
                    allowClear
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={"form-control" + (errors.password && touched.password ? "is-invalid" : "")}
                    placeholder="Enter password"
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </Form.Item>
                <Form.Item label="Tenant" required>
                  <Select
                    placeholder="Select Tenant"
                    name="tenant"
                    value={values.tenant}
                    onChange={(e) => setFieldValue("tenant", e)}
                    allowClear
                    className={"form-control" + (errors.tenant && touched.tenant ? "is-invalid" : "")}
                  >
                    {tenantList?.length && tenantList?.map(({ x_tenant_id, website_name }) => (
                      <Option value={x_tenant_id}>{website_name}</Option>
                    ))}
                  </Select>
                  <ErrorMessage name="tenant" component="div" className="invalid-feedback" />
                </Form.Item>
                <Form.Item style={{ float: "right" }}>
                  { !values?.emailAddress || !values?.password || !values?.tenant ?(
                  <Tooltip title="Please fill all the fields" color='#2db7f5'>
                    <Button type="primary" htmlType="submit" disabled={!values?.emailAddress || !values?.password || !values?.tenant}>
                      Submit
                    </Button>
                  </Tooltip>
                  ):(
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  )
                  }
                </Form.Item>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
})

export default Login;
