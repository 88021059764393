import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";

export class ServiceLocationsStore {    
    isLoading = false;
    serviceLocationsList = [];
    pageNo=1;
    pageSize=10;
    totalPages = null;
    serviceforCategory = [];
    services_on_LocID = [];
    operatingLocationsList = [];
    services = [];
    serviceLocations = [];
    constructor() {
      makeObservable(this, {
        getServiceLocationsList: action,
        isLoading: observable,
        serviceLocationsList: observable,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        getServices_onCategoryId: action,
        serviceforCategory: observable,
        getServices_onLocationId: action,
        services_on_LocID: observable,
        operatingLocationsList: observable,
        getOperatingLocations: action,
        services: observable,
        getServices: action,
        serviceLocations: observable,
        getServiceLocations: action
      });
    }

    getServiceLocationsList(currentPage, currentPageSize,  location, category, service) {
        this.isLoading = true;
        let url;
        if (location && !category && !service) {
          url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&location=${location}`
        } else if(!location && category && !service){
          url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&category=${category}`
        } else if(location && category && !service){
          url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&location=${location}&category=${category}`
        } else if(!location && !category && service){
          url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&service=${service}`
        }      
        else if(location && !category && service){
            url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&location=${location}&service=${service}`
        }
        else if(!location && category && service){
            url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&category=${category}&service=${service}`
        }
        else if(location && category && service){
            url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}&location=${location}&category=${category}&service=${service}`
        }else {
          url = `/service_location?pageNo=${currentPage}&pageSize=${currentPageSize}`
        }
        return io({
            url: url,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.serviceLocationsList = data?.data;
                    this.page = data?.metadata?.page;
                    this.totalPages = data?.metadata?.total;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.serviceLocationsList = [];
                this.page = 1;
                this.totalPages = 1;
            });
    }
    //get operating location for service
    getOperatingLocations() {
        return io({
          url: `/operatingLocation?fields=operating_location_name,localities,city,state,isOperating`,
          method: "GET",
        })
          .then(
            action(({data}) => {              
              this.operatingLocationsList = data?.data;
              this.isLoading = false;
              return data?.data;
            })
          )
          .catch((error) => {
            this.operatingLocationsList = [];
          });
      }
    //get services list based on categoryID
    getServices_onCategoryId( categoryID ) {
        this.isLoading = true;
        return io({
            url: `/service?fields=service_name&category_id=${categoryID}&isActive=true`,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.serviceforCategory = data?.data;
                    this.isLoading = false;
                    return data?.data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.serviceforCategory = [];
            });
    }
    createServiceLocation(fields,isAdd,opeLocID,categoryID,history){
        io({
          method: isAdd ? "post" : "put",
          url : isAdd ? `/service_location` : `service_location/${opeLocID}/${categoryID}`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Successfully Created Service Location`);
            history.push("/serviceLocations");
          })
          .catch((error) => {
            const { response: { data = {} } = {} } = error
            message.error(`${data?.message}`,5);
            console.log(error);
          });
      };

      //get service location on service location id or _id

      getServices_onLocationId( serviceLocationID ) {
        this.isLoading = true;
        return io({
            url: `/service_location/service/get_service_location/${serviceLocationID}`,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.services_on_LocID = data;
                    this.isLoading = false;
                    return data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.services_on_LocID = [];
            });
    }
    getServices() {
      this.isLoading = true;
      return io({
          url: `/service?fields=service_name&isActive=true`,
          method: "GET",
      })
          .then(
              action(({ data }) => {                    
                  this.services = data.data;
                  this.isLoading = false;
                  return data.data
              })
          )
          .catch((error) => {
              this.isLoading = false;
              this.services = [];
          });

    }
    getServiceLocations() {
      this.isLoading = true;
      return io({
          url: `/service_location`,
          method: "GET",
      })
          .then(
              action(({ data }) => {                    
                  this.serviceLocations = data?.data;
                  this.isLoading = false;
                  return data?.data;
              })
          )
          .catch((error) => {
              this.isLoading = false;
              this.serviceLocations = [];
          });
  }

}