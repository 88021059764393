import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class OrganizationAttributesStore {
  organizationAttributes = [];
  isLoading = true;

  constructor() {
    makeObservable(this, {
      getOrganizationAttributes: action,
      createOrganizationAttribute: action,
      organizationAttributes: observable,
      isLoading: observable,
      deleteRecord:action,
    });
  }

  getOrganizationAttributes() {
    return io({
      url: `/organizationAttributes`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.organizationAttributes = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.organizationAttributes = [];
      });
  }

  deleteRecord = (id) => {
    io({
      method: "delete",
      url: `/organizationAttributes/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getOrganizationAttributes();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  createOrganizationAttribute(fields, isAdd, recordId, closeModal){
    io({
      method: isAdd ? "post" : "put",
      url : isAdd ? `/organizationAttributes`: `/organizationAttributes/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} Organization Type`);
        this.getOrganizationAttributes();
        closeModal();
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.errmsg ? data?.errmsg : error?.message,
          duration: 5,
        });
      });
  };

}
