import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class OrganizationsStore {
  organizations = [];
  isLoading = true;
  pageNo= 1;
  pageSize=10;
  totalPages = null;
  organizationDetails = "";
  servicesList = [];
  doctors = [];
  orgUsers = [];
  diagnostics = []; 
  orgList = [];

  constructor() {
    makeObservable(this, {
      getOrganizations: action,
      getAllOrganizations:action,
      isLoading: observable,
      deleteRecord:action,
      createOrganization: action,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      organizations: observable,
      getOrganizationById: observable,
      organizationDetails: observable,
      getServicesList: action,
      servicesList: observable,
      getDoctors: action,
      doctors: observable,
      deleteDoctor: action,
      getOrgUsers: action,
      orgUsers: observable,
      diagnostics:observable,
      getDiagnostics:action,
      addDiscount: action,
      getPricingByOrg: action,
      orgList: observable,
      getOrgforLabRequest: action
    });
  }

getOrganizations(fields) {
    this.isLoading = false;
    return io({
      url: `/organizations${fields}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.organizations = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.organizations = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  getAllOrganizations() {
    this.isLoading = false;
    return io({
      url: `/organizations`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.organizations = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.organizations = [];
      });
  }

  getOrganizationById(id) {
    return io({
      url: `/organizations/${id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.organizationDetails = data;
        return data;
      })
      .catch((error) => {
        this.organizationDetails = [];
      });
  }

  deleteRecord = (id) => {
    io({
      method: "DELETE",
      url: `/organizations/${id}`,
    })
      .then(({data}) => {
        message.success(data?.message);
        this.getOrganizations(`?pageNo=${this.pageNo}&pageSize=${this.pageSize}`, );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createOrganization(fields, isAdd, recordId, history, name){
    io({
      method: isAdd ? "POST" : "PUT",
      url : `/organizations/${ !isAdd ? recordId : ""}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${isAdd ? "Added" : "updated"} ${name}`);
        history.push("/organizations");
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

      getServicesList() {
        return io({
            url: `/service?fields=service_name`,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.servicesList = data?.data;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.servicesList = [];
            });
    }

    // ========== Doctors============
    getDoctors() {
      return io({
          url: `/user?userType=PROFESSIONAL&fields=firstName,photo,specilization`,
          method: "GET",
      })
          .then(
              action(({ data }) => {
                  this.doctors = data?.data;
                  return data?.data;
              })
          )
          .catch((error) => {
              this.doctors = [];
          });
  }

  updateDoctor(professional_id, organization_id, closeModal){
    io({
      method: "PUT",
      url : `/organizations/${professional_id}/${organization_id}`,
    })
      .then(({ data }) => {
        message.success(data?.message);
        this.getOrganizationById(organization_id)
        closeModal();
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

  deleteDoctor(professional_id, organization_id){
    io({
      method: "DELETE",
      url : `/organizations/${professional_id}/${organization_id}`,
    })
      .then(({ data }) => {
        message.success(data?.message);
        this.getOrganizationById(organization_id)
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

getOrgUsers(currentPage, currentPageSize, searchText) {
  this.isLoading = false;
  return io({
    url: `/user?userType=ORG_ADMIN,ORG_SUPPORT,ORG_AGENT,ORG_TECH,ORG_SUPERVISOR&pageNo=${currentPage}&pageSize=${currentPageSize}&fields=firstName,mobileNumber,emailAddress,userType,isActive,createdAt,updatedAt&archived=false${searchText}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = true;
      this.orgUsers = data?.data;
      this.page = data?.metadata?.page;
      this.totalPages = data?.metadata?.total;
      return data?.data;
    })
    .catch((error) => {
      this.isLoading = true;
      this.orgUsers = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

// Diagnostics
getDiagnostics(type) {
  return io({
      url: `/diagnostics/investigations/type/${type}`,
      method: "GET",
  })
      .then(
          action(({ data }) => {
              this.diagnostics = data?.data;
              return data?.data;
          })
      )
      .catch((error) => {
          this.diagnostics = [];
      });
}

addDiscount(id, discount, handleCancel) {
  io({
    method: "PUT",
    url : `/organizations/discount/${id}/${discount}`,
  })
    .then(({ res }) => {
      message.success(`Successfully added mass discount`);
      this.getOrganizationById(id)
      handleCancel();
    })
    .catch((error) => {
      const { response: { data = {} } = {} } = error
      notification["error"]({
        description: data?.message,
        duration: 5,
      });
    });
};

// Pricing filters =================
getPricingByOrg(name) {
  return io({
    url: `/organizations/name/${name}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.organizations = data;
      return data;
    })
    .catch(error => {
      this.organizations = [];
      const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
    });
}

//get organizations list for labrequests filter

getOrgforLabRequest() {
  return io({
    url: `organizations?fields=shortName&isActive=true`,
    method: "GET",
  })
    .then(({ data }) => {
      this.orgList = data?.data;
      return data?.data;
    })
    .catch(error => {
      this.orgList = [];
    });
}
  
}
