import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class ReviewAttributesStore {
    reviewAttributesList = [];
    isLoading = true;
    reviewStrings = [];

  constructor() {
    makeObservable(this, {
      getReviewAttributes: action,
      reviewAttributesList: observable,
      isLoading: observable,
      deleteRecord:action,
      createReviewAttribute:action,
      getReviewStrings:action,
    });
  }

   getReviewAttributes() {
    return io({
      url: `/reviewattributes`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.reviewAttributesList = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.reviewAttributesList = [];
      });
  }

  deleteRecord = (id) => {
    io({
      method: "delete",
      url: `/reviewattributes/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getReviewAttributes()
      })
      .catch((error) => {
        message.success(error?.message);
      });
  };

  createReviewAttribute(fields, isAdd, recordId, name, history){
    return io({
      method: isAdd ? "POST" : "PUT",
      url : isAdd ? `/reviewattributes`: `/reviewattributes/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} ${name}`);
        this.getReviewAttributes();
        history.push("/review-attributes")
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        notification["error"]({
          description: data?.errmsg,
          duration: 5,
        });
      });
  };

  getReviewStrings(categoryID) {
    return io({
      url: `/reviewattributes/${categoryID}`,
      method: "GET",
    })
      .then(({ data }) => {
         this.reviewStrings = data?.reviewStrings;
         return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

}
