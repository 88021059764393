import { Form, Button, Divider, Space, Table } from "antd";
import React, { useState } from "react";
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useProductsStore } from "../../../../providers/RootStoreProvider";
import { observer } from "mobx-react-lite";
import AddMetaData from "./AddMetaData";

const MetaData = observer(({
    isAdd,
    product_id,
    metaData,
    setMetadata,
    handleSubmit,
    history,
    formData
}) => {
    const productsStore = useProductsStore();

    const [ openModal, handleOpenModal ] = useState({
        flagged: false,
        data: [],
        isEdit: false
    })

    const columns = [
        { title: 'Meta Name', dataIndex: 'name', key: 'name'},
        { title: 'Content', dataIndex: 'content', key: 'content'},
        { title: 'Action', key: 'operation', fixed: 'right', width: 100, render: (data) =><Button type="link" onClick={() =>  handleEdit(data)}><EditOutlined /></Button> },
    ];

    const data = metaData?.length && metaData?.map((data, index) => ({
        index: index,
        name: data.name,
        content: data.content,
    }))

    const handleOpen = () => {
        handleOpenModal({
            flagged: true,
            data: [],
            isEdit: false
        })
    }

    const handleEdit = (data) => {
        handleOpenModal({
            flagged: true,
            data: data,
            isEdit: true,
            index: data.index
        })
    }

    const handleUpdate = () => {
        handleSubmit(formData)
    }

    const submitButton = () => {
        return (
          <Form.Item style={{ textAlign: "right", margin: "0"}}>
            <Space size="small">
              <Button onClick={() => history.push("/products")}>Cancel</Button>
              <Button type="primary" onClick={handleUpdate}>Submit</Button>
            </Space>
           </Form.Item>
        )
      }
    

    return (
        <Form >
            {openModal.flagged && (
                <AddMetaData
                    isAdd={true}
                    productsStore={productsStore}
                    metaData={metaData}
                    setMetadata={setMetadata}
                    isEdit={openModal.flagged}
                    open={openModal.flagged}
                    editData= {openModal}
                    handleClose={() => handleOpenModal({
                        flagged: false,
                        data: [],
                        isEdit: false
                    })}
                />
            )}
            
             <Button
                type={"primary" }
                style={{ float:"right", marginBottom:"10px" }}
                onClick={handleOpen}
            >
                {<PlusOutlined/> } Meta Data
            </Button>
            <Table size="small" columns={columns} dataSource={data} footer={() => submitButton()} />
            <Divider span={24} />
        </Form>
    )
})

export default MetaData;