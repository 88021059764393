import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class ContactsStore {
  contacts = [];
  isLoading = false;
  pageNo= 1;
  pageSize=10;
  totalPages = null;

  constructor() {
    makeObservable(this, {
      getContacts: action,
      contacts: observable,
      isLoading: observable,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
    });
  }

   getContacts(currentPage, currentPageSize, searchText) {
    this.isLoading = true;
    return io({
      url: `/contact?pageNo=${currentPage}&pageSize=${currentPageSize}${searchText}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.contacts = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.contacts = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

}
