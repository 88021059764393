import { Button, Form, Input, Modal, Space } from "antd";
import { Formik } from "formik";
import React from "react";
import { observer } from "mobx-react-lite";
import ErrorMessageComponent from "../../../../components/ErrorMessage";
import { MetaDataValidationSchema } from "../ProductsValidationSchema";

const AddMetaData = observer (({ metaData, setMetadata, open, handleClose, editData}) => {

  const createMetaData = (fields) => {
    if (editData?.isEdit) {
      metaData[editData.index] = fields;
      setMetadata([...metaData]);
    } else {
      if (metaData?.length) {
        setMetadata([fields, ...metaData]);
      } else {
        setMetadata([fields]);
      }
    }
    handleClose();
  };

  return (
    <>
      <Modal
        title={`${!editData?.isEdit ? `Add` : `Edit`} Meta Data`}
        visible={open}
        onCancel={handleClose}
        footer={false}
        okText="Submit"
        width={450}
        destroyOnClose={true}
      >
        <Formik
          initialValues={{
            name: editData?.data?.name || "",
            content: editData?.data?.content || null,
          }}
          validationSchema={MetaDataValidationSchema}
          onSubmit={(fields) => {
            createMetaData(fields);
          }}
        >
          
          {({ handleSubmit, values, errors,handleChange, touched }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Meta Name" required>
            <Input
                    placeholder="Enter name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    allowClear
                    className={"form-control" + (errors.name && touched.name? " is-invalid": "")}
                  />
                   <ErrorMessageComponent name="name" />
            </Form.Item>
              <Form.Item label="Content">
              <Input.TextArea
                      placeholder="Enter content"
                      name="content"
                      value={values.content}
                      onChange={handleChange}
                      allowClear
                      className={"form-control" + (errors.content && touched.content? " is-invalid": "")}
                    />
                    <ErrorMessageComponent name="content" />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Space>
                  <Button onClick={() => handleClose()}>Close</Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
})

export default AddMetaData;
