import React, { useState, Suspense, lazy } from "react";
import { Layout } from "antd";
import "../../App.scss";
import { Content } from "antd/lib/layout/layout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SiderComponent from "../../components/SiderComponent";
import HeaderComponent from "../../components/HeaderComponent";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import FooterComponent from "../../components/FooterComponent";
import Login from "../userValidation/Login";
import { useAuthDetails } from "../../context/AuthContext";
import AddProduct from "../services/products/AddProduct";

const LayoutComponent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { isAuthenticated } = useAuthDetails();

  const iconImg = React.createElement(
    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
    {
      className: "trigger",
      onClick: () => setCollapsed(!collapsed),
    }
  );

  const ProfessionalDetails = lazy(() => import('../professionals/professionalDetails/ProfessionalDetails'));
  const OperatingLocations = lazy(() => import('../general/operating-locations/OperatingLocations'));
  const AddOperatingLocation = lazy(() => import('../general/operating-locations/AddOperatingLocation'));
  const ReviewAttributes = lazy(() => import('../general/review-attributes/ReviewAttributes'));
  const AddEditReviewAttribute = lazy(() => import('../general/review-attributes/AddEditReviewAttribute'));
  const OrganizationTypes = lazy(() => import('../general/organization-types/OrganizationTypes'));
  const OrganizationAttributes = lazy(() => import('../general/organizarion-attributes/OrganizationAttributes'));
  const Contacts = lazy(() => import('../general/contacts/Contacts'));
  const TermsAndConditions = lazy(() => import('../general/terms-and-conditions/TermsAndConditions'));
  const AddTermsAndCondition = lazy(() => import('../general/terms-and-conditions/AddTermsAndCondition'));
  const TermsConditionsMapping = lazy(() => import('../general/terms-conditions-mapping/TermsConditionsMapping'));
  const Categories = lazy(() => import('../general/categories/Categories'));
  const CommonImages = lazy(() => import('../general/common-images/CommonImages'));
  const Admins = lazy(() => import('../Users/Admin/Admins.js'));
  const SupportUsers = lazy(() => import('../Users/Support-Users/SupportUsers'));
  const Customers = lazy(() => import('../Users/Customers/Customers'));
  const Archived = lazy(() => import('../Users/Archived/ArchivedUsers'));
  const AddEditUser = lazy(() => import('../Users/Admin/AddEditUser'));
  const ProfessionalMaster = lazy(() => import('../professionals/professionalMaster/ProfessionalsMaster'));
  const AddProfessionalMaster = lazy(() => import('../professionals/professionalMaster/AddProfessionalMaster'));
  const Services = lazy(() => import('../services/services-home/Services'));
  const AddEditService = lazy(() => import('../services/services-home/AddEditService'));
  const ServiceLocations = lazy(() => import('../services/serviceLocations/ServiceLocations'));
  const AddEditServiceLocation = lazy(() => import('../services/serviceLocations/AddEditServiceLocation'));
  const Products = lazy(() => import('../services/products/Products'));
  const ServiceMapping = lazy(() => import('../services/serviceMapping/ServiceMapping'));
  const Orders = lazy(() => import('../orders/orders/Orders'));
  const ViewOrder = lazy(() => import('../orders/orders/ViewOdrer'));
  const Questions = lazy(() => import('../services/questions/Questions'));
  const AddQuestions = lazy(() => import('../services/questions/AddQuestions'));
  const FollowUps = lazy(() => import('../orders/followUps/FollowUps'))
  const ViewFallowup = lazy(() => import('../orders/followUps/ViewFollowUp'))
  const Refunds = lazy(() => import('../orders/refund/Refund'))
  const EditRefund = lazy(() => import('../orders/refund/EditRefund'))
  const Organizations = lazy(() => import('../organizations/Organizations'))
  const AddOrganization = lazy(() => import('../organizations/AddOrganization'))
  const CaseSheets = lazy(() => import('../services/casesheets/CaseSheets'));
  const AddCaseSheet = lazy(() => import('../services/casesheets/AddCaseSheet'));
  const Tenants = lazy(() => import('../Tenants/Tenants'))
  const AddEditTenant = lazy(() => import('../Tenants/AddEditTenant'))
  const Articles = lazy(() => import('../articles/articles/Articles'));
  const AddArticle = lazy(() => import('../articles/articles/AddArticle'));
  const MyUsers = lazy(() => import('../organizations/my-users/MyUsers'));
  const Investigations = lazy(() => import('../medicals/investigations/Investigations'));
  const AddInvestigations = lazy(() => import('../medicals/investigations/AddInvestigation'));
  const ViewConsultationDetails = lazy(() => import('../orders/orders/view-consultation-details/ViewConsultationDetails'))
  const ProfessionalSlots = lazy(() => import('../professionals/slots/ProfessionalSlots'));
  const AddSlots = lazy(() => import('../professionals/slots/CreateSlot'));

  const CustomerReviews = lazy(() => import('../feedbacks/customerReviews/CustomerReviews'));
  const UpdateCustomerReview = lazy(() => import('../feedbacks/customerReviews/UpdateCustomerReview'));
  const HealthGroups = lazy(() => import('../medicals/health-groups/HealthGroups'));
  const AddHealthGroup = lazy(() => import('../medicals/health-groups/AddHealthGroup'));
  const Diagnostics = lazy(() => import('../medicals/diagnostics/Diagnostics'));
  const ViewDiagnostic = lazy(() => import('../medicals/diagnostics/ViewDiagnostic'));
  const Voucher = lazy(() => import('../vouchers/voucher/Voucher'));
  const UpdateVoucher = lazy(() => import('../vouchers/voucher/UpdateVoucher'));
  const Consultations = lazy(() => import('../medicals/Consultations'));
  const Pricing = lazy(() => import('../medicals/Pricing'));
  const Payments = lazy(() => import('../orders/Payments'));
  const UploadCSV = lazy(() => import('../vouchers/voucher/UploadCSV'));
  const HealthCardOrders = lazy(() => import('../orders/health-card-orders/HealthCardOrders'))
  const Pricings = lazy(() => import('../pricing/Pricings'));
  const HealthNetworks = lazy(() => import('../health-cards/health-networks/HealthNetworks'));
  const AddHealthNetwork = lazy(() => import('../health-cards/health-networks/AddHealthNetwork'));
  const HealthCard = lazy(() => import('../health-cards/health-card/HealthCard'));
  const AddHealthCard = lazy(() => import('../health-cards/health-card/AddHealthCard'));
  const LabRequests = lazy(() => import('../orders/lab-requests/LabRequests'));
  const ViewLabRequests = lazy(() => import('../orders/lab-requests/VeiwLabRequest'));

  return (
    <React.Fragment>
      <Router>
        {isAuthenticated ? (
          <React.Fragment>
              <HeaderComponent icon={iconImg} isAuthenticated={isAuthenticated} />
              <Layout>
                <SiderComponent collapsed={collapsed} />
                <Layout>
                  <Layout style={{ padding: "48px 4px 4px" }}>
                    <Content
                      className="site-layout-background"
                      style={{
                        padding: 24,
                        margin: 0,
                        minHeight: '89vh',
                      }}
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                      <Switch>
                        <Route exact path="/categories" component={props => <Categories {...props} />} />
                        <Route exact path="/operating-locations" component={props => <OperatingLocations {...props} />} />
                        <Route path="/operating-locations/create-operating-location" component={props => <AddOperatingLocation {...props} />} />
                        <Route path="/common-images" component={props => <CommonImages {...props} />} />
                        <Route exact path="/review-attributes" component={props => <ReviewAttributes {...props} />} />
                        <Route path="/review-attributes/create-review-attribute" component={props => <AddEditReviewAttribute {...props} />} />
                        <Route path="/organization-types" component={props => <OrganizationTypes {...props} />} />
                        <Route path="/organization-attributes" component={props => <OrganizationAttributes {...props} />} />
                        <Route path="/contacts" component={props => <Contacts {...props} />} />
                        <Route exact path="/terms-conditions" component={props => <TermsAndConditions {...props} />} />
                        <Route path="/terms-conditions/create-terms-conditions" component={props => <AddTermsAndCondition {...props} />} />
                        <Route path="/terms-conditions-mapping" component={props => <TermsConditionsMapping {...props} />} />
                        <Route path="/professional-details" component={props => <ProfessionalDetails {...props} />} />
                        <Route exact path="/users/admin" component={props => <Admins />} />
                        <Route path="/users/admin" component={props => <AddEditUser {...props} />} />
                        <Route exact path="/users/support-users" component={props => <SupportUsers {...props} />} />
                        <Route exact path="/users/customers" component={props => <Customers {...props} />} />
                        <Route exact path="/users/archived" component={props => <Archived {...props} />} />
                        <Route exact path="/professional-master" component={props => <ProfessionalMaster {...props} />} />
                        <Route exact path="/create-professional-master" component={props => <AddProfessionalMaster {...props} />} />
                        <Route exact path="/create-professional-master/:id" component={props => <AddProfessionalMaster {...props} />} />
                        <Route exact path="/services" component={props => <Services {...props} />} />
                        <Route path="/services" component={props => <AddEditService {...props} />} />
                        <Route exact path="/professional-master/:id" component={props => <AddProfessionalMaster {...props} />} />
                        <Route exact path="/serviceLocations" component={props => <ServiceLocations {...props} />} />
                        <Route path="/serviceLocations" component={props => <AddEditServiceLocation {...props} />} />
                        <Route exact path="/products" component={props => <Products {...props} />} />
                        <Route  path="/products/create-product" component={props => <AddProduct {...props} />} />
                        <Route exact path="/serviceMapping" component={props => <ServiceMapping {...props} />} />
                        <Route exact path="/orders" component={props => <Orders {...props} />} />
                        <Route  path="/orders/view-order" component={props => <ViewConsultationDetails {...props} />} />
                        <Route  path="/orders/create-order" component={props => <ViewOrder {...props} />} />
                        <Route exact path="/questions" component={props => <Questions {...props} />} />
                        <Route path="/questions" component={props => <AddQuestions {...props} />} />
                        <Route exact path="/followUps" component={props => <FollowUps {...props} />} />
                        <Route  path="/followUps/view-followUp" component={props => <ViewFallowup {...props} />} />
                        <Route exact path="/refunds" component={props => <Refunds {...props} />} />
                        <Route  path="/refunds/update-refund" component={props => <EditRefund {...props} />} />
                        <Route exact path="/organizations" component={props => <Organizations {...props} />} />
                        <Route  path="/organizations/create-organization" component={props => <AddOrganization {...props} />} />
                        <Route exact path="/casesheets" component={props => <CaseSheets {...props} />} />
                        <Route path="/casesheets/create-casesheet" component={props => <AddCaseSheet {...props} />} />
                        <Route exact path="/clients" component={props => <Tenants {...props} />} />
                        <Route path="/clients/AddEditClient" component={props => <AddEditTenant {...props} />} />
                        <Route exact path="/articles" component={props => <Articles {...props} />} />
                        <Route path="/articles/create-article" component={props => <AddArticle {...props} />} />
                        <Route exact path="/org-users" component={props => <MyUsers {...props} />} />
                        <Route exact path="/investigations" component={props => <Investigations {...props} />} />
                        <Route  path="/investigations/create-investigation" component={props => <AddInvestigations {...props} />} />
                        <Route exact path="/customer-reviews" component={props => <CustomerReviews {...props} />} />
                        <Route path="/customer-reviews" component={props => <UpdateCustomerReview {...props} />} />
                        <Route exact path="/health-groups" component={props => <HealthGroups {...props} />} />
                        <Route path="/health-groups/create-health-group" component={props => <AddHealthGroup {...props} />} />
                        <Route exact path="/diagnostics" component={props => <Diagnostics {...props} />} />
                        <Route path="/diagnostics/view-diagnostic" component={props => <ViewDiagnostic {...props} />} />
                        <Route exact path="/vouchers" component={props => <Voucher {...props} />} />
                        <Route path="/vouchers" component={props => <UpdateVoucher {...props} />} />
                        <Route path="/consultations" component={props => <Consultations {...props} />} />
                        <Route path="/pricing" component={props => <Pricing {...props} />} />
                        <Route path="/payments" component={props => <Payments {...props} />} />
                        <Route path="/voucher" component={props => <UploadCSV {...props} />} />
                        <Route exact path="/health-card-orders" component={props => <HealthCardOrders {...props} />} />
                        <Route path="/health-card-orders/create-health-card-order" component={props => <ViewOrder {...props} />} />
                        <Route path="/professional-pricing" component={props => <Pricings {...props} />} />
                        <Route path="/slots" component={props => <ProfessionalSlots {...props} />} />
                        <Route path="/AddSlots/:id" component={props => <AddSlots {...props} /> } />
                        <Route exact path="/health-card-categories" component={props => <HealthNetworks {...props} />} />
                        <Route path="/health-card-categories/create-health-card-category" component={props => <AddHealthNetwork {...props} />} />
                        <Route exact path="/health-card" component={props => <HealthCard {...props} />} />
                        <Route path="/health-card/create-health-card" component={props => <AddHealthCard {...props} />} />
                        <Route exact path="/lab-requests" component={props => <LabRequests {...props} />} />
                        <Route path="/lab-requests/view-lab-request" component={props => <ViewLabRequests {...props} />} />
                      </Switch>
                      </Suspense>
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            <FooterComponent type="inner" />
          </React.Fragment>
        ) : (
         <Switch>
            <Route
            exact path="/login"
            render={() =>  
              <Login /> 
            }
          />
            <Route
            exact path="/"
            render={() =>  
              <Login /> 
            }
          />
         </Switch>
        )}
      </Router>
    </React.Fragment>
  );
}
export default LayoutComponent;