import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class LabRequestsStore {
  isLoading = true;
  pageNo= 1;
  pageSize=10;
  totalPages = null;
  healthCardOrder = "";
  labRequests = [];
  labRequestDetails = "";
  organizationUsers = [];

  constructor() {
    makeObservable(this, {
      getLabRequests:action,
      labRequests: observable,
      totalPages: observable,
      labRequestDetails: observable,
      getLabRequest_id: action,
      deleteLabRequest:action,
      createLabRequest: action,
      assignLineItem: action,
      organizationUsers: observable,
      getOrgUsers: action,
      getLabRequests_filter: action
    });
  }

// ================ lab-requests ==================

getLabRequests(currentPage, currentPageSize, searchName) {
  this.isLoading = true;
  return io({
    url: searchName ? `/labrequests?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchName}` : `/labrequests?pageNo=${currentPage}&pageSize=${currentPageSize}`,
    method: "GET",
  })
    .then(({ data: { data , metadata:{total,page}} }) => {
      this.isLoading = false;
      this.labRequests = data;
      this.page = page;
      this.totalPages = total;
      console.log(this.totalPages);
      return data?.data;
    })
    .catch((error) => {
      this.isLoading = false;
      this.labRequests = [];
      this.page = 1;
      this.totalPages = 1;
    });
}

getLabRequests_filter(orgID) {
  this.isLoading = false;
  return io({
    url: `/labrequests?organizationID=${orgID}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.isLoading = true;
      this.labRequests = data?.data;
      return data?.data;
    })
    .catch((error) => {
      const { response: { data = {} } = {} } = error
      message.error(data.message , 3);
      this.isLoading = true;
      this.labRequests = [];
    });
}

getLabRequest_id(id) {
  return io({
    url: `/labrequests/${id}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.labRequestDetails = data;
      return data;
    })
    .catch((error) => {
      this.fallowUps = [];
    });
}

deleteLabRequest = (id) => {
  return io({
    method: "DELETE",
    url: `/labrequests/${id}`,
  })
    .then((data) => {
      message.success("Deleted successfully");
      this.getOrders(this.pageNo, this.pageSize);
    })
    .catch((error) => {
      console.log(error);
    });
};

createLabRequest(fields, labRequestID, history, name){
  io({
    method: "PUT",
    url : `/labrequests/${labRequestID}`,
    data: fields,
  })
    .then(({ res }) => {
      message.success(`Successfully updated ${name}`);
      history.push("/lab-requests");
    })
    .catch((error) => {
      const { response: { data = {} } = {} } = error
      notification["error"]({
        description: data?.message,
        duration: 5,
      });
    });
};

assignLineItem(fields, labRequestID, testID, handleClose, name, setLineItems){
  io({
    method: "PUT",
    url : `/labrequests/${labRequestID}/${testID}`,
    data: fields,
  })
    .then(({ res }) => {
      message.success(`Successfully assigned ${name}`);
      this.getLabRequest_id(labRequestID).then((data) => {
        setLineItems(data?.lineItems)
       })
      handleClose();
    })
    .catch((error) => {
      const { response: { data = {} } = {} } = error
      console.log(error);
      notification["error"]({
        description: data?.message,
        duration: 5,
      });
    });
};

getOrgUsers(role, setAgents, setTechnicians) {
  return io({
    url: `/organization-users?role=${role}`,
    method: "GET",
  })
    .then(({ data }) => {
      this.organizationUsers = data;
      setAgents(data)
      setTechnicians(data)
      return data;
    })
    .catch((error) => {
      this.organizationUsers = [];
    });
}

}
