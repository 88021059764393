import { observable, action, makeObservable } from "mobx";
import { message, notification } from "antd";
import { io } from "../api/io";

const queryString = require('query-string');

export class ProfessionalStore {
    allProfessionalList= [];
    pageNo= 1;
    pageSize=10;
    totalPages = null;
    isLoading=true;
    allProfessionalMasterList= [];
    locationList = [];
    categoryBasedOnService = [];
    localities = [];
    professionalDetail = [];
    productListByID = [];
    professionalSlot = [];
    generated_slots = [];
    uploadedImageUrl = {};

    constructor(){
        makeObservable(this, {
            allProfessionalList: observable,
            getProfessionalList: action,
            pageNo: observable,
            pageSize: observable,
            totalPages: observable,
            isLoading: observable,
            uploadPublicFiles: action,
            getLocationList: action,
            locationList: observable,
            categoryBasedOnService: observable,
            getCategoryBasedOnLocation: action,
            localities: observable,
            create: action,
            professionalDetail: observable,
            getProfessionalById: action,
            productListByID: observable,
            getProductListById: action,
            professionalSlot: observable,
            getProfessionalSlots: action,
            generated_slots: observable,
            uploadDocImage: action,
            uploadedImageUrl: observable,
            createFaq: action,
            deleteFaq: action,
            searchProfessional: action
        })
    }

    searchProfessional(search_key){  
        this.isLoading = true;
        return io({
            url: `user/search_user?userRole=PROFESSIONAL&searchText=${search_key}&fields=firstName,mobileNumber,emailAddress,isActive,userType,createdAt,updatedAt&archived=false`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.allProfessionalList= data?.data;
                this.isLoading = false;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.allProfessionalList = [];
              }
        )
    }

    getProfessionalList(query){  
        const parsedQueryString = queryString.stringify(query, {arrayFormat: 'comma'});
        this.isLoading = true;
        return io({
            url: `/user?${parsedQueryString}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.allProfessionalList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.allProfessionalList = [];
                this.page = 1;
                this.totalPages = 1;
              }
        )
    }

    getCategoryBasedOnLocation(_id){
        return io({
            url: `service_location/${_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.categoryBasedOnService= data?.data;
            })
        ).catch(
            () => {
                this.categoryBasedOnService= [];
              }
        )
    }
    getProfessionalMasterList(currentPage, currentPageSize, isArchived, location, service, professional){   
        this.isLoading = true;
        return io({
            url: `servicesOffered?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=service_name,professional_id,professional_name,operating_location_name,isActive,archived,createdAt,updatedAt&archived=${isArchived === undefined ? false : isArchived}${location}${service}${professional}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.allProfessionalMasterList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.allProfessionalMasterList = [];
                this.page = 1;
                this.totalPages = 1;
              }
        )
    }

    uploadPublicFiles(file){
        return io({
            url: `public/file`,
            method: "POST",
            data: file
        }).then(
            action(({data}) => {
              return data
            })
        ).catch(
            (error) => {
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    description: {message},
                  });
              }
        )
    }

    getLocationList(query){   
        const parsedQueryString = queryString.stringify(query, {arrayFormat: 'comma'});
        this.isLoading = true;
        return io({
            url: `/operatingLocation?${parsedQueryString}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.locationList = data.data;
                this.isLoading = false;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.localities = [];
                this.locationList = [];
              }
        )
    }

    getLocalitiesBasedOnLocation(_id){
        return io({
            url: `/operatingLocation/${_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.localities = data.localities.map(data => ({locality_id: data._id, localityName: data.localityName}));
            })
        ).catch(
            () => {
                this.localities = [];
              }
        )
    }

    create(payload){
        return io({
            url: 'servicesOffered',
            method: "POST",
            data: payload
        }).then(
            action(({data}) => {
                notification["success"]({
                    message: 'Success',
                    description: 'Details Created Successfully',
                  });   
            })
        ).catch((error) => {
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
              }
        )
    }

    update(id, payload){
        return io({
            url: `servicesOffered/${id}`,
            method: "PUT",
            data: {...payload}
        }).then(
            action(({data}) => {
                notification["success"]({
                    message: 'Success',
                    description: 'Details Updated Successfully',
                  });
            })
        ).catch((error) => {
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
              }
        )
    }

    getProfessionalById(_id){
        this.isLoading = true;
        return io({
            url: `servicesOffered/${_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.isLoading = false;
                this.professionalDetail = data;
                return data
            })
        ).catch((error) => {
                this.isLoading = false;
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
                // this.isLoading = false;
                this.professionalDetail = [];
              }
        )
    }


    getProfessionalSlots(_id, time_zone){
        this.isLoading = true;
        return io({
            url: `user/${_id}/slots?fields=generated_slots,firstName,updatedAt&timezone=${time_zone}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.isLoading = false;
                this.professionalSlot = data;
                this.generated_slots = data.generated_slots;
                return data
            })
        ).catch((error) => {
                this.isLoading = false;
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
                // this.isLoading = false;
                this.professionalDetail = [];
                this.generated_slots = [];
              }
        )
    }
 
    getProductListById(_id){
        this.isLoading = true;
        return io({
            url: `service/product_list/${_id}?fields=product_name product_price add_ons product_type`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.isLoading = false;
                this.productListByID = data.products;
                return data
            })
        ).catch((error) => {
                this.isLoading = false;
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
                // this.isLoading = false;
                this.productListByID = [];
              }
        )
    }

    updateSlotStatus(professional_id, slot_time_id, payload){
        this.isLoading = true;
        return io({
            url: `user/admin/slot_status/${professional_id}/${slot_time_id}?aval=${payload}`,
            method: "PUT",
        }).then(
            action(({data}) => {
                this.isLoading = false;
                this.generated_slots = data;
                notification["success"]({
                    message: 'Success',
                    description: "Status Updated successfully",
                  });
                return data
            })
        ).catch((error) => {
                this.isLoading = false;
                this.generated_slots = [];
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
              }
        )
    }

    uploadDocImage(file){
        const fmData = new FormData();
        fmData.append("image", file);
        return io({
            url: `/file`,
            method: "POST",
            data: fmData
        }).then(
            action(({data}) => {
                notification["success"]({
                    message: 'Success',
                    description: data.msg,
                  });
                return data;
            })
        ).catch((error) => {
                const { response: { data: { message } = {} } = {} } = error;
                notification["error"]({
                    message: 'Error',
                    description: message,
                  });
              }
        )
    }

    UpdateOrganizationTab(user_id, org_id) {
        return io({
          url: `/user/${user_id}/${org_id}`,
          method: "PUT",
        })
          .then(({ data }) => {
            notification["success"]({
                message: 'Success',
                description: data.message,
              });
            return data;
          })
          .catch((error) => {
            const { response: { data: { message } = {} } = {} } = error;
            notification["error"]({
                message: 'Error',
                description: message,
              });
          });
      }

      deleteOrganization(professional_id, organization_id) {
        return io({
          url: `/user/${professional_id}/${organization_id}`,
          method: "DELETE",
        })
          .then(({ data }) => {
            notification["success"]({
                message: 'Success',
                description: data.message,
              });
            return data;
          })
          .catch((error) => {
            const { response: { data: { message } = {} } = {} } = error;
            notification["error"]({
                message: 'Error',
                description: message,
              });
          });
      }
    //  ========  Faqs  ==========

      createFaq(fields, isAdd, user_id, handleClose, setFaqs, userStore ){
        return io({
          method: isAdd ? "POST" : "PUT",
          url : `/faq/${user_id}`,
          data: fields,
        })
          .then(({ res }) => {
            userStore.getAdminData_Id(user_id).then((data) => {
                setFaqs(data.faq.faq);
              });
            message.success(`Successfully ${isAdd ? "Added" : "updated"} Faq`);
            handleClose();
          })
          .catch((error) => {
            const { response: { data = {} } = {} } = error
            notification["error"]({
              description: data?.errmsg,
              duration: 5,
            });
          });
      };

      deleteFaq(user_id, setFaqs, userStore){
        io({
          method: "DELETE",
          url : `/faq/${user_id}`,
        })
          .then(({ data }) => {
            message.success(data?.message);
            userStore.getAdminData_Id(user_id).then((data) => {
                setFaqs(data);
              });
          })
          .catch((error) => {
            const { response: { data = {} } = {} } = error
            notification["error"]({
              description: data?.message,
              duration: 5,
            });
          });
      };

}