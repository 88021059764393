import { Select, Form, Row, Col, Button, Input, Space, Tabs, Upload, message } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import RichTextEditor from '../../../components/reusable/RichTextEditor';
import ErrorMessageComponent from '../../../components/ErrorMessage';
import { useProductsStore, useProfessionalStore, useTenantsStore } from "../../../providers/RootStoreProvider";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import { ProductsValidationSchema } from "./ProductsValidationSchema";
import BackButton from "../../../components/BackButton"
import Faqs from "../../../components/Faqs"
import MetaData from './meta-data/MetaData';

const { TabPane } = Tabs;

const AddProduct = observer(() => {
  let location = useLocation();
  let history = useHistory();
  let product_id = location?.state?.product_id

  let isAdd = history.location?.state?.isAdd

  const data = history.location.state.initialData;

  let [initialData, handleInitialData] = useState(data?._id ? data : {});

  const[logo, setLogo] = useState(initialData?.bannerImage);
  const[icon, setIcon] = useState(initialData?.iconImage);

const tenantsStore = useTenantsStore()
const professionalStore = useProfessionalStore();
const productsStore = useProductsStore();
const { productDetails } = productsStore;

const productType = ['CHAT_CONSULTING', 'VIDEO_CONSULTING', 'OP_CONSULTING', 'HOME_CONSULTING']

useEffect(() => {
  productsStore.getProductDetailsById(product_id);
}, [productsStore, product_id]);

const [ faqs, setFaqs ] = useState( productDetails?.length ? productDetails?.faq : [] )
const [ metaData, setMetadata ] = useState( productDetails?.length ? productDetails?.metadata : [] )
const [activeTab,setActiveTab] = useState("1");

useEffect(() => {
  setFaqs(productDetails?.faq);
  setMetadata(productDetails?.metadata);
},[productDetails?.faq, productDetails?.metadata])

const ref = useRef(null);

if(ref?.current?.values?.product_name){
    initialData = ref?.current?.values
}

const createProduct = (fields) => {
  fields.bannerImage = logo;
  fields.iconImage = icon;
  console.log(fields);
  productsStore.createProduct({...fields, faq: faqs, metadata: metaData }, isAdd, product_id, history);
}


  const createFaqAndMeta = (fields) => {
    if (initialData?.product_name) {
      let payload = { ...initialData, bannerImage: logo, iconImage: icon, faq: faqs, metadata: metaData, _id: product_id }
      productsStore.createProduct(payload, isAdd, product_id, history);
    } else {
      changeTab("1")
      message.info(`Please Fill all the required fields`)
    }
  }


const upload = (options, iconType) => {
  console.log(iconType);
  const { onSuccess, onError, file, onProgress } = options;
  const fmData = new FormData();
  const config = {
      onUploadProgress: (event) => {
          onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
  };
  fmData.append("image", file);
  if(iconType==="logo") {
      tenantsStore.uploadImage(fmData, config, onSuccess, onError, setLogo, file)
  } else {
      tenantsStore.uploadImage(fmData, config, onSuccess, onError, setIcon, file)
  }
}

const changeTab = activeKey => {
  setActiveTab(activeKey)
};

return (
  <React.Fragment>
    <BackButton
      pageName={
        isAdd
          ? "Create Product"
          : `Update Product : ${initialData?.product_name}`
      }
    />
    <Tabs activeKey={activeTab} size="large" onChange={changeTab}>
      <TabPane tab="Basic" key="1">
        <Formik
          innerRef={ref}
          initialValues={{
            product_name: initialData?.product_name || undefined,
            aboutProduct: initialData?.aboutProduct || "",
            product_price: initialData?.product_price || "",
            isActive:
              initialData?.isActive === false
                ? false
                : initialData?.isActive || undefined,
            product_type: initialData?.product_type || undefined,
            timeToComplete: initialData?.timeToComplete || "",
            notes: initialData?.notes || "",
            iconImage: initialData?.iconImage || undefined,
            bannerImage: initialData?.bannerImage || undefined,
          }}
          validationSchema={ProductsValidationSchema}
          onSubmit={(fields) => {
            createProduct(fields);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              className="content-edit"
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Product Type">
                    <Select
                      name="product_type"
                      value={values.product_type}
                      placeholder="select product type"
                      onChange={(value) => setFieldValue("product_type", value)}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {productType?.length && productType?.map((item, index) => (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Product Name" required>
                    <Input
                      placeholder="Enter product name"
                      name="product_name"
                      value={values.product_name}
                      onChange={handleChange}
                      allowClear
                      className={
                        "form-control" +
                        (errors.product_name && touched.product_name
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessageComponent name="product_name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Price">
                    <Input
                      placeholder="Enter product price"
                      name="product_price"
                      type="number"
                      value={values.product_price}
                      onChange={handleChange}
                      allowClear
                      className={
                        "form-control" +
                        (errors.product_price && touched.product_price
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessageComponent name="product_price" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Time to complete">
                    <Input
                      placeholder="Enter time to complete"
                      name="timeToComplete"
                      type="number"
                      value={values.timeToComplete}
                      onChange={handleChange}
                      allowClear
                      className={
                        "form-control" +
                        (errors.timeToComplete && touched.timeToComplete
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessageComponent name="timeToComplete" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Notes">
                    <Input
                      placeholder="Enter notes"
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Status">
                    <Select
                      name="isActive"
                      value={values.isActive}
                      placeholder="select status"
                      onChange={(value) => setFieldValue("isActive", value)}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      <Select.Option value={true} key={1}>
                        Active
                      </Select.Option>
                      <Select.Option value={false} key={0}>
                        Inactive
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="About Product">
                <RichTextEditor
                  name="aboutProduct"
                  value={values.aboutProduct}
                  schema={{ min: 0, max: 1000 }}
                  onChange={(e) => setFieldValue("aboutProduct", e)}
                  productsStore={productsStore}
                  professionalStore={professionalStore}
                  placeholder="Enter about product"
                />
              </Form.Item>
              <Row>
                  <Col span={12} style={{ paddingRight: "10px" }}>
                      <Form.Item label="Banner Image" >
                          <Upload
                              accept="image/*"
                              defaultFileList={values.bannerImage ? [{ url: values.bannerImage}] : []}
                              customRequest={(event) => upload(event, "logo")}
                              listType="picture"
                              maxCount={1}
                              onRemove={() => setLogo("")}
                          >
                              <Button type="primary">Upload Logo</Button>
                          </Upload>
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item label="Icon Image" >
                          <Upload
                              accept="image/*"
                              defaultFileList={values.iconImage ? [{ url: values.iconImage}] : []}
                              customRequest={(event) => upload(event, "icon")}
                              listType="picture"
                              maxCount={1}
                              onRemove={() => setIcon("")}
                          >
                              <Button type="primary">Upload Icon</Button>
                          </Upload>
                      </Form.Item>
                  </Col>
              </Row>
              <Form.Item style={{ textAlign: "right" }}>
                <Space>
                  <Button onClick={() => history.push("/products")}>
                    Close
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </TabPane>
      <TabPane tab="FAQ's" key="2">
        <Faqs
          isAdd={isAdd}
          product_id={product_id}
          faqs={faqs}
          setFaqs={setFaqs}
          history={history}
          handleSubmit={createFaqAndMeta}
          formData={initialData}
        />
      </TabPane>
      <TabPane tab="Meta Data" key="3">
        <MetaData
          isAdd={isAdd}
          product_id={product_id}
          metaData={metaData}
          setMetadata={setMetadata}
          history={history}
          handleSubmit={createFaqAndMeta}
          formData={initialData}
        />
      </TabPane>
    </Tabs>
  </React.Fragment>
);
})

export default AddProduct
