import { Form, Button, Divider, Space, Table } from "antd";
import React, { useState } from "react";
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import AddFaq from "../Pages/services/products/AddFaq";
import { useProductsStore } from "../providers/RootStoreProvider";
import { observer } from "mobx-react-lite";

const Faqs = observer(({
    isAdd,
    product_id,
    faqs,
    setFaqs,
    handleSubmit,
    history,
    formData
}) => {
    const productsStore = useProductsStore();

    const [ openModal, handleOpenModal ] = useState({
        flagged: false,
        data: [],
        isEdit: false
    }) 



    const columns = [
        { title: 'Step No.', dataIndex: 'stepNo', key: 'stepNo'},
        { title: 'Short Description', dataIndex: 'shortDescription', key: 'shortDescription'},
        { title: 'Long Description', dataIndex: 'longDescription', key: 'longDescription'},
        { title: 'Action', key: 'operation', fixed: 'right', width: 100, render: (data) =><Button type="link" onClick={() =>  handleEdit(data)}><EditOutlined /></Button> },
    ];

    const data = faqs?.length && faqs?.map((data, index) => ({
        index: index,
        shortDescription: data.shortDescription,
        longDescription: data.longDescription,
        stepNo: data.stepNo,
    }))

    const handleOpen = () => {
        handleOpenModal({
            flagged: true,
            data: [],
            isEdit: false
        })
    }

    const handleEdit = (data) => {
        handleOpenModal({
            flagged: true,
            data: data,
            isEdit: true,
            index: data.index
        })
    }


    const submitButton = () => {
        return (
          <Form.Item style={{ textAlign: "right", margin: "0"}}>
            <Space size="small">
              <Button onClick={() => history.push("/products")}>Cancel</Button>
              <Button type="primary" onClick={() => handleSubmit(formData) }>Submit</Button>
            </Space>
           </Form.Item>
        )
      }
    

    return (
        <Form >
            {openModal.flagged && (
                <AddFaq
                    isAdd={true}
                    productsStore={productsStore}
                    faqs={faqs}
                    setFaqs={setFaqs}
                    isEdit={openModal.flagged}
                    open={openModal.flagged}
                    editData= {openModal}
                    handleClose={() => handleOpenModal({
                        flagged: false,
                        data: [],
                        isEdit: false
                    })}
                />
            )}
            
             <Button
                type={"primary" }
                style={{ float:"right", marginBottom:"10px" }}
                onClick={handleOpen}
            >
                {<PlusOutlined/> } Faq
            </Button>
            <Table size="small" columns={columns} dataSource={data} footer={() => submitButton()} />
            <Divider span={24} />
        </Form>
    )
})

export default Faqs;