import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import jwt_decode from "jwt-decode";
import { io } from "../api/io";
import Cookies from "js-cookie";
import { isClientSide } from "../utils/utils";
import { Spin } from "antd";

let accessToken = "";
let user = {};

export const setAccessToken = (token) => {
  accessToken = token;
  if (token) {
    user = jwt_decode(token);
    localStorage.setItem("auth", true);
    localStorage.setItem("user_id", user._id);
    localStorage.setItem("user_name", user.firstName.concat(` ${user.lastName}`))
    localStorage.setItem("roles", JSON.stringify(user.userType.map(i=>i.role)));
  } else {
    user = {};
    localStorage.setItem("auth", false);
  }
};

export const getAccessToken = () => accessToken;

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isClientSide && localStorage.getItem("auth") === "true") {
      console.log("in auth context refresh token call");
      const user_id = localStorage.getItem("user_id");
      setLoading(true);
      io({ method: "post", url: `/refreshToken/${user_id}` })
        .then(({ data = {} } = {}) => {
          const { accessToken } = data;
          setAccessToken(accessToken);
          setIsAuthenticated(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          logout();
          alert("Your session has timed out. Please login again.");
          window.location = "/login";
        });
    }
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState(
    getAccessToken() ? true : false
  );
  const { userType: userRoles = [] } = user;

  const login = (data) => {
    const { accessToken } = data;
    setAccessToken(accessToken);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    const { _id } = user || {};
    if (_id) {
      await io({ method: "put", url: `/user/logout/${_id}` }).then(() => {
        console.log("successfully Logged out");
        window.location = "/login"
      });
    }
    setAccessToken("");
    setIsAuthenticated(false);
    if (isClientSide) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("roles");
      Cookies.remove("refreshToken");
    }
  };

  const hasRole = (roleName) =>
    userRoles.some((userData) => userData["role"] === roleName);

  const isProfessional = () => hasRole("PROFESSIONAL"); 
  const isSuperAdmin = () => hasRole("SUPER_ADMIN");
  const isAdmin = () => hasRole("ADMIN");
  const isProductAdmin = () => hasRole("PRODUCT_ADMIN");
  const isSupportAdmin = () => hasRole("SUPPORT_ADMIN");
  const isOrgSupport = () => hasRole("ORG_SUPPORT")
  const isOrgAdmin = () => hasRole("ORG_ADMIN")

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25%",
        }}
      >
        <Spin />
      </div>
    );

  return (
    <Fragment>
      <AuthContext.Provider
        value={{
          login,
          logout,
          user,
          isAuthenticated,
          userRoles,
          isProfessional,
          isSuperAdmin,
          isAdmin,
          isProductAdmin,
          isSupportAdmin,
          isOrgSupport,
          isOrgAdmin,
          hasRole,
        }}
      >
        {children}
      </AuthContext.Provider>
    </Fragment>
  );
};

export default AuthProvider;

export const useAuthDetails = () => {
  const userDetails = useContext(AuthContext);
  return userDetails ? userDetails : {};
};

export function useAuth() {
  return useContext(AuthContext);
}
