import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class PricingsStore {
  pricings = [];
  isLoading = false;
  pageNo= 1;
  pageSize=10;
  totalPages = null;

  constructor() {
    makeObservable(this, {
      getPricings: action,
      pricings: observable,
      isLoading: observable,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
    });
  }

   getPricings(currentPage, currentPageSize, location, service, professional) {
    this.isLoading = true;
    return io({
      url: `/servicesOffered?pageNo=${currentPage}&pageSize=${currentPageSize}${location}${service}${professional}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.pricings = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.pricings = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

}
