import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";
import { observer } from "mobx-react-lite";

export class ServiceMappingStore {    
    isLoading = false;
    pageNo=1;
    pageSize=10;
    totalPages = null;
    serviceCasesheet = [];
    caseSheetsList = [];
    servicesList = [];
    serviceCasesheetList = [];
    services_CaseSheets = [];
    constructor() {
      makeObservable(this, {
        isLoading: observable,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        serviceCasesheet:observable,
        getServicesMappingList:action,
        caseSheetsList:observable,
        getCaseSheetsList:action,
        servicesList:observable,
        getServicesList:action,
        serviceCasesheetList:observable,
        getServicesListonServiceCasesheetId:action,
        getServices_CaseSheets: action,
        services_CaseSheets: observable
      });
    }
    getServicesMappingList(currentPage, currentPageSize, service ,casesheet) {
        this.isLoading = true;
        let url;
        if (service && !casesheet) {
          url = `/service_casesheet?pageNo=${currentPage}&pageSize=${currentPageSize}&service=${service}`
        } else if(!service && casesheet){
          url = `/service_casesheet?pageNo=${currentPage}&pageSize=${currentPageSize}&casesheet=${casesheet}`
        } else if(service && casesheet){
          url = `/service_casesheet?pageNo=${currentPage}&pageSize=${currentPageSize}&service=${service}&casesheet=${casesheet}`
        }else {
          url = `/service_casesheet?pageNo=${currentPage}&pageSize=${currentPageSize}`
        }
        return io({
            url: url,
            method: "GET"
        })
            .then(
                action(({ data }) => {                    
                    this.serviceCasesheet = data?.data;
                    this.page = data?.metadata?.page;
                    this.totalPages = data?.metadata?.total;
                    this.isLoading = false;
                    return data?.data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.serviceCasesheet = [];
                this.page = 1;
                this.totalPages = 1;
                const { response: { data = {} } = {} } = error
                message.error(data.message);
            });
    }
    getCaseSheetsList() {
        this.isLoading = true;
        return io({
            url: `/casesheet?fields=casesheet_name`,
            method: "GET"
        })
            .then(
                action(({ data }) => {                    
                    this.caseSheetsList = data?.data;
                    this.isLoading = false;
                    return data?.data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.caseSheetsList = [];
            });
    }
    getServicesList() {
        this.isLoading = true;
        return io({
            url: `/service?fields=service_name&isActive=true&archived=false`,
            method: "GET"
        })
            .then(
                action(({ data }) => {                    
                    this.servicesList = data?.data;
                    this.isLoading = false;
                    return data?.data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.servicesList = [];
            });
    }
    getServicesListonServiceCasesheetId(service_casesheet_id) {
        this.isLoading = true;
        return io({
            url: `/service_casesheet/${service_casesheet_id}`,
            method: "GET"
        })
            .then(
                action(({ data }) => {                    
                    this.serviceCasesheetList = data;
                    this.isLoading = false;
                    return data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.serviceCasesheetList = [];
            });
    }

    createServiceCaseSheetMapping(fields,isAdd,service_casesheet_id){
        io({
          method: isAdd ? "post" : "put",
          url : isAdd ? `/service_casesheet` : `/service_casesheet/${service_casesheet_id}`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Success`);
            this.getServicesMappingList(1,10)
            this.getServices_CaseSheets()
          })
          .catch((error) => {
            console.log(error);
          });
    };
    getServices_CaseSheets() {
        this.isLoading = true;
        return io({
            url: `/service_casesheet`,
            method: "GET"
        })
            .then(
                action(({ data }) => {                    
                    this.services_CaseSheets = data?.data;
                    this.page = data?.metadata?.page;
                    this.totalPages = data?.metadata?.total;
                    this.isLoading = false;
                    return data?.data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.services_CaseSheets = [];
            });
    }

}