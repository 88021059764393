import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";
import { observer } from "mobx-react-lite";

export class CustomerReviewsStore {    
    isLoading = false;
    customerReviewList = [];
    pageNo= 1;
    pageSize=10;
    totalPages = null;
    reviewDataonId = {};
    profList = [];
    servList = []
    constructor() {
      makeObservable(this, {
        isLoading: observable,
        customerReviewList:observable,
        getCustomerReviewList:action,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        reviewDataonId: observable,
        getCustomerReviews_BasedOn_Id: action,
        profList: observable,
        getProfessionalList : action,
        servList: observable,
        getServicesList: action
      });
    }
    getCustomerReviewList(currentPage, currentPageSize, searchValue, status){   
        this.isLoading = true;
        let url;
        if (searchValue && status == undefined) {
          url = `/customerreviews/admin/reviews?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}`
        } else if (!searchValue && status != undefined) {
          url = `/customerreviews/admin/reviews?pageNo=${currentPage}&pageSize=${currentPageSize}&showReview=${status}`
        } else if (searchValue && status != undefined) {
          url = `/customerreviews/admin/reviews?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&showReview=${status}`
        } else {
          url = `/customerreviews/admin/reviews?pageNo=${currentPage}&pageSize=${currentPageSize}`
        }
        return io({
            url:url,
            method: "GET",
        }).then(
            action(({data}) => {
                this.customerReviewList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.customerReviewList = [];
                this.page = 1;
                this.totalPages = 1;
              }
        )
    }
    getCustomerReviews_BasedOn_Id =(review_id) => {
        this.isLoading = true;
        return io({
            url: `/customerreviews/${review_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.reviewDataonId = data
                this.isLoading = false;
                return data;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.reviewDataonId = {};
              }
        )
      }

    deleteReview = (review_id) => {
        io({
          method: "delete",
          url: `/customerreviews/${review_id}`,
        })
          .then((data) => {
            message.success("Deleted successfully");
            this.getCustomerReviewList(1,10)
          })
          .catch((error) => {
            console.log(error);
          });
    };
    
    updateReviewStatus = (fields,review_id,show_review) => {
        this.isLoading = true;
        io({
          method: "put",
          url: `/customerreviews/enablereview/${review_id}?showReview=${show_review}`,
          data: fields
        })
          .then((data) => {
            this.isLoading = false;
            message.success("Updated successfully");
            this.getCustomerReviews_BasedOn_Id(review_id)
            
          })
          .catch((error) => {
            console.log(error);
          });
    };


    getProfessionalList(){   
      this.isLoading = true;
      return io({
          url: `user?userType=PROFESSIONAL&fields=firstName,lastName,mobileNumber`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.profList= data?.data;
              this.page = data?.metadata?.page;
              this.totalPages = data?.metadata?.total;
              this.isLoading = false;
          })
      ).catch(
          () => {
              this.isLoading = false;
              this.profList = [];
              this.page = 1;
              this.totalPages = 1;
            }
      )
  }

  getServicesList(){   
    this.isLoading = true;
    return io({
        url: `service?fields=service_name`,
        method: "GET",
    }).then(
        action(({data}) => {
            this.servList= data?.data;
            this.page = data?.metadata?.page;
            this.totalPages = data?.metadata?.total;
            this.isLoading = false;
        })
    ).catch(
        () => {
            this.isLoading = false;
            this.servList = [];
            this.page = 1;
            this.totalPages = 1;
          }
    )
  }
  reviewFilter(searchID){
    this.isLoading = true;
    return io({
        url: `customerreviews?${searchID}`,
        method: "GET",
    }).then(
        action(({data}) => {
            this.customerReviewList= data?.data;
            this.page = data?.metadata?.page;
            this.totalPages = data?.metadata?.total;
            this.isLoading = false;
        })
    ).catch(
        (error) => {
            this.isLoading = false;
            this.customerReviewList = [];
            this.page = 1;
            this.totalPages = 1;
            const { response: { data = {} } = {} } = error
            message.error(`${data?.message}`,5);
          }
    )
  }
}


