import { UserOutlined, TeamOutlined, LayoutFilled, StarFilled , UsergroupAddOutlined, SnippetsFilled, MedicineBoxFilled,
          IdcardFilled, GoldenFilled, ApartmentOutlined, DollarCircleFilled, MailFilled, GiftFilled } from '@ant-design/icons';

export const routes = [
  {
    title: "Users",
    icon: UserOutlined,
    role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/users/admin",
        name: "Admin",
        role: ["ADMIN", "SUPER_ADMIN"],
      },
      {
        link: "/users/support-users",
        name: "Support Users",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/users/customers",
        name: "Customers",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/users/archived",
        name: "Archived (Deleted)",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "General",
    icon: LayoutFilled,
    role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/categories",
        name: "Categories",
        role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN"],
      },
      {
        link: "/operating-locations",
        name: "Operating Locations",
        role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/common-images",
        name: "Common Images",
        role: ["SUPER_ADMIN", "PRODUCT_ADMIN", "ADMIN"],
      },
      {
        link: "/review-attributes",
        name: "Review Attributes",
        role: ["SUPER_ADMIN", "PRODUCT_ADMIN", "ADMIN"],
      },
      {
        link: "/organization-types",
        name: "Organization Types",
        role: ["SUPER_ADMIN", "PRODUCT_ADMIN", "ADMIN"],
      },
      {
        link: "/organization-attributes",
        name: "Organization Attributes",
        role: ["SUPER_ADMIN", "PRODUCT_ADMIN", "ADMIN"],
      },
      {
        link: "/contacts",
        name: "Contacts",
        role: ["SUPER_ADMIN", "SUPPORT_ADMIN", "ADMIN"],
      },
      {
        link: "/terms-conditions",
        name: "Terms and Conditions",
        role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/terms-conditions-mapping",
        name: "T&C Mapping",
        role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Services",
    icon: StarFilled,
    role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/services",
        name: "Services",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/serviceLocations",
        name: "Service Locations",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/serviceMapping",
        name: "Service Mapping",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/questions",
        name: "Questions",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/casesheets",
        name: "Case Sheets",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/products",
        name: "Products",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Professionals",
    icon: UsergroupAddOutlined,
    role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/professional-details",
        name: "Professional Details",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/professional-master",
        name: "Professional Master",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/slots",
        name: "Slots",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Articles",
    icon: SnippetsFilled,
    role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/articles",
        name: "Articles",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Medicals",
    icon: MedicineBoxFilled,
    role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/consultations",
        name: "Consultations",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/diagnostics",
        name: "Diagnostics Master",
        role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/investigations",
        name: "Investigations",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/health-groups",
        name: "Health Groups",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/pricing",
        name: "Pricing",
        role: ["ADMIN", "SUPER_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Health Cards",
    icon: IdcardFilled,
    role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/health-card-categories",
        name: "Health Networks",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
      {
        link: "/health-card",
        name: "Health Card",
        role: ["ADMIN", "SUPER_ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Orders",
    icon: GoldenFilled,
    role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "ORG_SUPPORT"],
    subMenu: [
      {
        link: "/orders",
        name: "Orders",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
      {
        link: "/followUps",
        name: "Follow ups",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
      {
        link: "/refunds",
        name: "Refunds",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
      {
        link: "/health-card-orders",
        name: "Health Card Orders",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
      {
        link: "/payments",
        name: "Payment Requests",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
      {
        link: "/lab-requests",
        name: "Lab Requests",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
    ],
  },
  {
    title: "Organizations",
    icon: ApartmentOutlined,
    role: [
      "SUPER_ADMIN",
      "ADMIN",
      "SUPPORT_ADMIN",
      "PRODUCT_ADMIN",
      "ORG_ADMIN",
      "ORG_SUPPORT",
    ],
    subMenu: [
      {
        link: "/organizations",
        name: "Organizations",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "PRODUCT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
      {
        link: "/org-users",
        name: "My Users",
        role: [
          "SUPER_ADMIN",
          "ADMIN",
          "SUPPORT_ADMIN",
          "ORG_ADMIN",
          "ORG_SUPPORT",
        ],
      },
    ],
  },
  {
    title: "Pricing",
    icon: DollarCircleFilled,
    role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/professional-pricing",
        name: "Professional Pricing",
        role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
  {
    title: "Tenants",
    icon: TeamOutlined,
    role: ["SUPER_ADMIN"],
    subMenu: [{ link: "/clients", name: "Clients", role: ["SUPER_ADMIN"] }],
  },
  {
    title: "Feedbacks",
    icon: MailFilled,
    role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/customer-reviews",
        name: "Customer Reviews",
        role: ["SUPER_ADMIN", "ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },

  {
    title: "Vouchers",
    icon: GiftFilled,
    role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
    subMenu: [
      {
        link: "/vouchers",
        name: "Voucher",
        role: ["SUPER_ADMIN", "ADMIN", "PRODUCT_ADMIN", "SUPPORT_ADMIN"],
      },
    ],
  },
];
