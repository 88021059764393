import { message } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class OperatingLocationStore {
    operatingLocationsList = [];
    isLoading = false;

    constructor() {
      makeObservable(this, {
        getOperatingLocations: action,
        createOperatingLocation:action,
        deleteOperatingLocation: action,
        operatingLocationsList: observable,
        isLoading:observable,
      });
    }

    getOperatingLocations(params, searchText, isActive) {
        return io({
          url: `/operatingLocation${params}${isActive == undefined ? '' : isActive}${searchText == undefined ? '' : searchText}`,
          method: "GET",
        })
          .then(
            action(({data}) => {
              this.isLoading = true;
              this.operatingLocationsList = data?.data;
              return data?.data;
            })
          )
          .catch((error) => {
            this.operatingLocationsList = [];
          });
      }

      deleteOperatingLocation(id) {
        io({
          method: "delete",
          url: `/operatingLocation/${id}`,
        })
          .then((data) => {
            message.success("Deleted successfully");
            this.getOperatingLocations("");
          })
          .catch((error) => {
            console.log(error);
          });
      };

      createOperatingLocation(fields, isAdd, recordId, history){
       return io({
          method: isAdd ? "POST" : "PUT",
          url: `/operatingLocation/${ !isAdd ? recordId : ""}`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Successfully ${ isAdd ? "Added" : "updated"} Operating Location`);
            history.push("/operating-locations");
          })
          .catch((error) => {
            message.error(error?.message);
          });
      };

}
