import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { observable, action, makeObservable } from "mobx";
import { getBaseUrl, io } from "../api/io";

export class CategoryStore {
  categoryList = [];
  isLoading = true;
  pictureUrl = "";

  constructor() {
    makeObservable(this, {
      getCategoryList: action,
      categoryList: observable,
      isLoading: observable,
      deleteRecord:action,
      pictureUrl: observable,
    });
  }

   getCategoryList() {
    return io({
      url: `/category?fields=categoryCode,shortName,createdAt,updatedAt,picture,description`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.categoryList = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = true;
        this.categoryList = [];
      });
  }

  deleteRecord = (id) => {
    io({
      method: "delete",
      url: `/category/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getCategoryList()
      })
      .catch((error) => {
        console.log(error);
      });
  };


  createCategory(fields, isAdd, recordId, closeModal){
    io({
      method: "post",
      url : isAdd ? `/category`: `/category/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} Category`);
        this.getCategoryList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  uploadImage(options) {
    console.log(getBaseUrl());
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { X_TENANT_ID: Cookies.get("x_tenant_id") },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    fmData.append("image", file);
    axios
      .post(`${getBaseUrl()}/iclap-api/file`, fmData, config)
      .then((res) => {
        onSuccess(file);
        message.success(res.data.msg);
        this.pictureUrl = res.data.url;
      })
      .catch((err) => {
        const error = new Error('Some error');
        onError({event:error});
      });
  };

}
