import { Button, Form, Input, Modal, Space } from "antd";
import { Formik } from "formik";
import React from "react";
import { observer } from "mobx-react-lite";
import ErrorMessageComponent from "../../../components/ErrorMessage";
import { FaqsValidationSchema } from "./ProductsValidationSchema";

const AddFaq = observer (({ faqs, setFaqs, open, handleClose, editData}) => {

  const createRole = (fields) => {
    if (editData?.isEdit) {
      faqs[editData.index] = fields;
      setFaqs([...faqs]);
    } else {
      if (faqs?.length) {
        setFaqs([fields, ...faqs]);
      } else {
        setFaqs([fields]);
      }
    }
    handleClose();
  };

  return (
    <>
      <Modal
        title={`${!editData?.isEdit ? `Add` : `Edit`} Steps`}
        visible={open}
        onCancel={handleClose}
        footer={false}
        okText="Submit"
        width={450}
        destroyOnClose={true}
      >
        <Formik
          initialValues={{
            stepNo: editData?.data?.stepNo || "",
            shortDescription: editData?.data?.shortDescription || "",
            longDescription: editData?.data?.longDescription || "",
          }}
          validationSchema={FaqsValidationSchema}
          onSubmit={(fields) => {
            createRole(fields);
          }}
        >
          
          {({ handleSubmit, values, errors,handleChange, touched }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Step Number" required>
            <Input
                    placeholder="Enter step number"
                    name="stepNo"
                    type="number"
                    min={1}
                    value={values.stepNo}
                    onChange={handleChange}
                    allowClear
                    className={"form-control" + (errors.stepNo && touched.stepNo? " is-invalid": "")}
                  />
                  <ErrorMessageComponent name="stepNo" />
            </Form.Item>
              <Form.Item label="Short Description" required>
              <Input.TextArea
                      placeholder="Enter short description"
                      name="shortDescription"
                      value={values.shortDescription}
                      onChange={handleChange}
                      allowClear
                      className={"form-control" + (errors.shortDescription && touched.shortDescription? " is-invalid": "")}
                    />
                    <ErrorMessageComponent name="shortDescription" />
              </Form.Item>
              <Form.Item label="Long Description">
              <Input.TextArea
                      placeholder="Enter long description"
                      name="longDescription"
                      rows={4}
                      value={values.longDescription}
                      onChange={handleChange}
                      allowClear
                      className={"form-control" + (errors.longDescription && touched.longDescription? " is-invalid": "")}
                    />
                    <ErrorMessageComponent name="longDescription" />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Space>
                  <Button onClick={() => handleClose()}>Close</Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
})

export default AddFaq;
