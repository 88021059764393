import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";
export class TenantsStore {
    isLoading = false;
    tenantsData = [];
    tenantData = {};
    pageNo=1;
    pageSize=5;
    totalPages = null;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            getTenants: action,
            tenantsData: observable,
            getTenantById: observable,
            tenantData: observable,
            pageNo: observable,
            pageSize: observable,
            totalPages: observable,
        })
    }

    getTenants(pageNo, pageSize, searchValue) {
        this.isLoading = true;
        return io({
            url: searchValue ?`tenants?pageNo=${pageNo}&pageSize=${pageSize}&searchText=${searchValue}` : `tenants?pageNo=${pageNo}&pageSize=${pageSize}`,
            method: "GET",
        })
            .then(
                action(({ data }) => {
                    this.tenantsData = data?.data;
                    this.pageNo = data?.metadata?.page;
                    this.pageSize = pageSize;
                    this.totalPages = data?.metadata?.total;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.adminsData = []
                this.page = 1;
                this.totalPages = 1;
            });
    }

    getTenantById(id) {
        return io({
            url: `tenants/${id}`,
            method: "GET",
        })
            .then(action(({ data }) => {
                this.tenantData = data;
                return data;
            })
            )
            .catch((error) => {
                this.tenantData = {}
            });
    }

    addEdit(isAdd, id, payload, history) {
        io({
            url: isAdd ? "/tenants" : `/tenants/${id}`,
            method: isAdd ? "POST" : "PUT",
            data: payload
        }).then(({ data }) => {
            if(isAdd) {
                message.success("Tenant Added Successfully")
            } else {
                message.success("Tenant Updated Successfully")
            }
            history.push("/clients")
        }).catch((err) => {
            message.error(err?.response?.data?.message)
        })
    }

    deleteTenant(id) {
        return io({
            url: `tenants/${id}`,
            method: "DELETE",
        })
            .then(action(({ data }) => {
                this.getTenants(1, this.pageSize)
                message.success("Tenant Deleted Successfully")
            })
            )
            .catch((error) => {
                message.error(error.res.message)
            });
    }

    uploadImage(fmData, config, onSuccess, onError, setImage_value, file) {
        io({
            url: "file",
            method: "POST",
            data: fmData, 
            config,
        }).then(({ data }) => {
            onSuccess(file);
            setImage_value(data?.url)
        }).catch(err => {
            message.error(err.response.message)
            const error = new Error('Some error');
            onError({event:error});
        })
    }
}