import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message, notification } from "antd";
import { observer } from "mobx-react-lite";

export class ArticleStore {    
    isLoading = false;
    articlesList = [];
    pageNo= 1;
    pageSize=10;
    totalPages = null;
    professsionalsData = [];
    services = [];
    products = [];
    ArticleDataonId = [];
    servicesList=[];
    allProfessionalList=[]
    constructor() {
      makeObservable(this, {
        isLoading: observable,
        articlesList:observable,
        getArticlesList:action,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        getProfessionalsData: action,
        professsionalsData: observable,
        services:observable,
        products:observable,
        getProducts: action,
        getServices: action,
        ArticleDataonId: observable,
        getArticles_BasedOnArticleId:action,
        getServicesList: action,
        getProfessionalList: action,
        servicesList: observable,
        allProfessionalList: observable
      });
    }
    getArticlesList(currentPage, currentPageSize, search, prod, service, prof){   
        this.isLoading = true;
        let url = `/articles?pageNo=${currentPage}&pageSize=${currentPageSize}`
        if(search){
            url += `&searchText=${search}`
        }
        if(prod){
            url += `&product=${prod}`
        }
        if(service){
            url += `&service=${service}`
        }
        if(prof){
            url += `&professional=${prof}`
        }

        return io({
            url: url,
            method: "GET",
        }).then(
            action(({data}) => {
                this.articlesList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            (error) => {
                this.isLoading = false;
                this.articlesList = [];
                this.page = 1;
                this.totalPages = 1;
                const { response: { data = {} } = {} } = error
                message.error(`${data?.message}`,5);
              }
        )
    }
    createArticle(fields, author_id, isAdd,article_id) {
        io({
          method: isAdd ? "post" : "put",
          url: isAdd ? `/article/${author_id}` : `/article/${author_id}/${article_id}`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Successfully ${isAdd ? "Created" : "Updated"} Article`);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      
      getArticles_BasedOnArticleId =(article_id) => {
        this.isLoading = true;
        return io({
            url: `/article/${article_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.ArticleDataonId = data
                this.isLoading = false;
                return data;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.ArticleDataonId = {};
              }
        )
      }

    deleteArticle = (author_id,article_id) => {
        io({
          method: "delete",
          url: `/article/${author_id}/${article_id}`,
        })
          .then((data) => {
            message.success("Deleted successfully");
            this.getArticlesList(1,10)
          })
          .catch((error) => {
            console.log(error);
          });
    };

    getProfessionalsData() {
        this.isLoading = true;
        return io({
            url: `/user?userType=PROFESSIONAL&fields=firstName,lastName,mobileNumber&isActive=true&archived=false`,
            method: "GET",
        })
            .then(
                action(({ data }) => {
                    this.professsionalsData = data?.data;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.professsionalsData = []
            });
    }
    getProducts() {
        this.isLoading = true;
        return io({
            url: `/product?fields=product_name&isActive=true`,
            method: "GET",
        })
            .then(
                action(({ data }) => {
                    this.products = data?.data;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.products = []
            });
    }
    getServices() {
        this.isLoading = true;
        return io({
            url: `/service?fields=service_name&isActive=true`,
            method: "GET",
        })
            .then(
                action(({ data }) => {
                    this.services = data?.data;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.services = []
            });
    }
    uploadArticleImage(fmData, config, onSuccess, onError, setImage_value, file) {
        io({
            url: "file",
            method: "POST",
            data: fmData, 
            config,
        }).then(({ data }) => {
            onSuccess(file);
            setImage_value(data?.url)
        }).catch(err => {
            message.error(err.response.message)
            const error = new Error('Some error');
            onError({event:error});
        })
    }
    getServicesList() {
      this.isLoading = true;
      return io({
          url:`service?fields=service_name&isActive=true&archived=false`,
          method: "GET",
      })
          .then(
              action(({ data }) => {                    
                  this.servicesList = data?.data;
                  this.isLoading = false;
                  return data?.data;
              })
          )
          .catch((error) => {
              this.isLoading = false;
              this.servicesList = [];
              const { response: { data = {} } = {} } = error
              message.error(data.message);
          });
    }
    getProfessionalList(){        
      this.isLoading = true;
      return io({
          url: `/user?fields=firstName,lastName&userType=PROFESSIONAL`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.allProfessionalList= data?.data;
              this.isLoading = false;
              return data?.data;

          })
      ).catch(
          () => {
              this.isLoading = false;
              this.allProfessionalList = [];
            }
      )
  }

}


