import { enableStaticRendering } from "mobx-react-lite";
import { createContext, useContext } from "react";
import { RootStore } from "../store/RootStore";

enableStaticRendering(typeof window === "undefined");

let store;
const StoreContext = createContext(undefined);
StoreContext.displayName = "StoreContext";

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }

  return context;
}

export function useLoginStore() {
  const { loginStore } = useRootStore();
  return loginStore;
}

export function useCategoryStore() {
  const { categoryStore } = useRootStore();
  return categoryStore;
}

export function useProfessionalStore() {
  const { professionalStore } = useRootStore();
  return professionalStore;
}

export function useCommonImagesStore() {
  const { commonImagesStore } = useRootStore();
  return commonImagesStore;
}

export function useUserStore() {
  const { userStore } = useRootStore();
  return userStore;
}

export function useOperatingLocationStore() {
  const { operatingLocationStore } = useRootStore();
  return operatingLocationStore;
}

export function useOrganizationTypesStore() {
  const { organizationTypesStore } = useRootStore();
  return organizationTypesStore;
}

export function useContactsStore() {
  const { contactsStore } = useRootStore();
  return contactsStore;
}

export function useOrganizationAttributesStore() {
  const { organizationAttributesStore } = useRootStore();
  return organizationAttributesStore;
}

export function useTCMappingsStore() {
  const { tcMappingsStore } = useRootStore();
  return tcMappingsStore;
}

export function useReviewAttributesStore() {
  const { reviewAttributesStore } = useRootStore();
  return reviewAttributesStore;
}

export function useServicesStore() {
  const { servicesStore } = useRootStore();
  return servicesStore;
}

export function useTermsConditionsStore() {
  const { termsConditionsStore } = useRootStore();
  return termsConditionsStore;
}

export function useServiceLocationsStore() {
  const { serviceLocationsStore } = useRootStore();
  return serviceLocationsStore;
}

export function useProductsStore() {
  const { productsStore } = useRootStore();
  return productsStore;
}

export function useServiceStore() {
  const { serviceStore } = useRootStore();
  return serviceStore;
}

export function useServiceMappingStore() {
  const { serviceMappingStore } = useRootStore();
  return serviceMappingStore;
}
export function useOrdersStore() {
  const { ordersStore } = useRootStore();
  return ordersStore;
}

export function useQuestionsStore() {
  const { questionsStore } = useRootStore();
  return questionsStore;
}
export function useRefundssStore() {
  const { refundsStore } = useRootStore();
  return refundsStore;
}

export function useOrganizationsStore() {
  const { organizationsStore } = useRootStore();
  return organizationsStore;
}

export function useCaseSheetsStore() {
  const { caseSheetsStore } = useRootStore();
  return caseSheetsStore;
}

export function useTenantsStore() {
  const { tenantsStore } = useRootStore()
  return tenantsStore;
}

export function useArticleStore() {
  const { articleStore } = useRootStore();
  return articleStore;
}

export function useInvestigationsStore() {
  const { investigationssStore } = useRootStore();
  return investigationssStore;
}

export function useCustomerReviewsStore() {
  const { customerReviewsStore } = useRootStore();
  return customerReviewsStore;
}

export function useVoucherStore() {
  const { voucherStore } = useRootStore();
  return voucherStore;
}

export function usePricingsStore() {
  const { pricingsStore } = useRootStore();
  return pricingsStore;
}

export function useHealthCardsStore() {
  const { healthCardsStore } = useRootStore();
  return healthCardsStore;
}

export function useLabRequestsStore() {
  const { labRequestsStore } = useRootStore();
  return labRequestsStore;
}

export function RootStoreProvider({ children, hydrationData }) {
  const store = initializeStore(hydrationData);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

function initializeStore(initialData) {
  const _store = store ?? new RootStore();

  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}
