import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import {routes} from "./routes";
import { useAuthDetails } from "../context/AuthContext";
import Icon from '@ant-design/icons';

const { Sider } = Layout;

const SiderComponent = (props) => {
  const { userRoles } = useAuthDetails();
  let roles = userRoles.map(i=>i.role)

  return (
    <>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        collapsedWidth="0"
        breakpoint="lg"
        width={256}
        // minHeight: "90vh", maxHeight: "auto"
        style={{ top: "48px" }}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["0"]}
          style={{ 
            overflow: 'auto',
            width: !props.collapsed ? 256 : 0,
            height: '100vh',
            position: !props.collapsed ? 'fixed' : '',
            left: 0,
        }}
        >
          {routes
          .filter(({ role }) => role?.some((o) => roles.includes(o)))
          .map(({title, subMenu, icon}, index) => (
            <>
            <SubMenu key={index} title={title} icon={<Icon component={icon} size={5}/> }>              
              {subMenu
              .filter(({ role }) => role?.some((o) => roles.includes(o)))
              .map(({link, name}, index) => (
                 <Menu.Item key={link}>
                  <Link to={link}>{name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
            </>
          ))}
        </Menu>
      </Sider>
    </>
  );
}
export default SiderComponent;
