import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";
import { observer } from "mobx-react-lite";

export class CaseSheetsStore {    
    isLoading = false;
    caseSheetsList = [];
    pageNo= 1;
    pageSize=10;
    totalPages = null;
    caseSheetsDataonId= {};
    caseSheets_Questions_DataonId={};
    questions_for_mapping=[]
    constructor() {
      makeObservable(this, {
        isLoading: observable,
        caseSheetsList:observable,
        getCaseSheetsList:action,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        caseSheetsDataonId: observable,
        getCaseSheetsBasedOnId:action,
        caseSheets_Questions_DataonId: observable,
        getCaseSheets_QuestionsBasedOnId:action,
        questions_for_mapping:observable,
        getQuestions_for_CaseSheetMapping:action
      });
    }
    getCaseSheetsList(currentPage, currentPageSize, searchText){   
        this.isLoading = true;
        return io({
            url: searchText ? `casesheet?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchText}` : `casesheet?pageNo=${currentPage}&pageSize=${currentPageSize}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.caseSheetsList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            (error) => {
                this.isLoading = false;
                this.caseSheetsList = [];
                this.page = 1;
                this.totalPages = 1;
                const { response: { data = {} } = {} } = error
                message.error(data.message);
              }
        )
    }
    getCaseSheetsBasedOnId(casesheet_id){
        this.isLoading = true;
        return io({
            url: `/casesheet/${casesheet_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.caseSheetsDataonId = data
                this.isLoading = false;
                return data;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.caseSheetsDataonId = {};
              }
        )
    }
    createCaseSheet(fields, isAdd, casesheet_id) {
      io({
        method: isAdd ? "post" : "put",
        url: isAdd ? `/casesheet` : `/casesheet/${casesheet_id}`,
        data: fields,
      })
        .then(({ res }) => {
          message.success(`Successfully ${isAdd ? "Created" : "Updated"} Case Sheet`);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    createCaseSheetQuestionMapping (fields){
        io({
          method: "post",
          url: `/casesheet_question`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Successfully Updated Case Sheet`);
            this.getCaseSheets_QuestionsBasedOnId(fields.casesheet_id)
          })
          .catch((error) => {
            console.log(error);
          });
      };


    updateCaseSheet_QuestionsMapping(fields, casesheet_id) {
      io({
        method: "put",
        url: `/casesheet_question/questions/${casesheet_id}`,
        data: fields,
      })
        .then(({ res }) => {
          message.success(`Successfully Updated Case Sheet`);
          this.getCaseSheets_QuestionsBasedOnId(casesheet_id)
        })
        .catch((error) => {
          console.log(error);
        });
    };
    deleteCaseSheet = (casesheet_id, setPagination) => {
        io({
          method: "delete",
          url: `/casesheet/${casesheet_id}`,
        })
          .then((data) => {
            message.success("Deleted successfully");
            setPagination({current: 1, pageSize: 10, total: null})
            this.getCaseSheetsList(this.pageNo, this.pageSize)
          })
          .catch((error) => {
            console.log(error);
          });
    };
    getCaseSheets_QuestionsBasedOnId =(casesheet_id) => {
      this.isLoading = true;
      return io({
          url: `/casesheet_question/questions/${casesheet_id}`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.caseSheets_Questions_DataonId = data
              this.isLoading = false;
              return data;
          })
      ).catch(
          () => {
              this.isLoading = false;
              this.caseSheets_Questions_DataonId = {};
            }
      )
    }
    getQuestions_for_CaseSheetMapping =() => {
      this.isLoading = true;
      return io({
          url: `/question`,
          method: "GET",
      }).then(
          action(({data}) => {
              this.questions_for_mapping = data?.data
              this.isLoading = false;
              // return data;
          })
      ).catch(
          () => {
              this.isLoading = false;
              this.questions_for_mapping = [];
            }
      )
    }

}