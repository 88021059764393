
import React, {useState} from "react";
import {Card} from 'antd';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const isEmptyString = ({ blocks }) =>
  blocks.length === 1 && blocks[0].text === '';
const toMarkup = content => {
  const raw = convertToRaw(content);
  return isEmptyString(raw) ? '' : draftToHtml(raw);
};
export default function RichTextEditor ({
    professionalStore,
    onChange,
    value='',
    placeholder
}) {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
  
    const [ editorState, handleEditorState ] = useState(
        EditorState.createWithContent(contentState)
    );

    const onEditorStateChange = (state) => {
        handleEditorState(state)
        onChange(toMarkup(editorState.getCurrentContent()));
    };


    const imageUploadCallBack = file => new Promise(
        (resolve) => {
            const formData = new FormData();
            formData.append('UploadFiles', file, file.name);

            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            // let url = ''
            reader.onload = function (e) {
                img.src = this.result
            };

            img.onload = function () {
                //console.log(img); // Get the image
                // console.log(img.src.length)
                // Zoom the image needed canvas (can also directly define the canvas tag in the DOM, so that the compressed image can be directly displayed without rotating base64)
                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');

                // image original size
                let originWidth = this.width;
                let originHeight = this.height;

                // Maximum size limit, you can achieve image compression by setting the width and height
                let maxWidth = 400,
                    maxHeight = 500;
                // target size
                let targetWidth = originWidth,
                    targetHeight = originHeight;
                // Image size exceeds 300x300 limit
                if(originWidth > maxWidth || originHeight > maxHeight) {
                    if(originWidth / originHeight > maxWidth / maxHeight) {
                        // wider, limited by width
                        targetWidth = maxWidth;
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                    } else {
                        targetHeight = maxHeight;
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                    }
                }
                // canvas scales the image
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                // clear the canvas
                context.clearRect(0, 0, targetWidth, targetHeight);
                // Image Compression
                context.drawImage(img, 0, 0, targetWidth, targetHeight);
                /* The first parameter is the created img object; the second three parameters are the upper left corner coordinates, the latter two are the canvas area width and height */

                // compressed image to base64 url
                /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
                                     * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp format. The default value is 0.92*/
               //base64 format

                professionalStore.uploadPublicFiles(formData).then((res) => {
                    resolve({
                        data: {
                            link: res.url
                        }
                    })
                })
            }
        }
    );

        return (
            <div style={{marginBottom:'20px'}}>
                <Card>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="home-toolbar"
                        wrapperClassName="home-wrapper"
                        editorClassName="home-editor"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{ //Editor rich text component function control
                            history: { inDropdown: true },
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            image: {
                                urlEnabled: true,
                                uploadEnabled: true,
                                alignmentEnabled: true,   // Whether to display the sort button is equivalent to text-align
                                uploadCallback: imageUploadCallBack,  // Image processing (but only for local upload, url mode does not pass this function)
                                previewImage: true,
                                inputAccept: 'image/*',
                                alt: {present: false, mandatory: false}
                            }
                        }}
                        // onContentStateChange={onEditorChange}// Reference the function of the parent component
                        placeholder={placeholder} //Default content in the input box
                        spellCheck
                        localization={{ locale: 'en', translations: { 'generic.add': 'Add to' } }}
                    />
                </Card>
            </div>
        );  
}
