import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
const queryString = require('query-string');
export class ServiceStore {
 
    serviceList= [];

    constructor(){
        makeObservable(this, {
          serviceList: observable,
          getServiceList: action
        })
    }

    getServiceList(query){   
        const parsedQueryString = queryString.stringify(query, {arrayFormat: 'comma'});
        this.isLoading = true;
        return io({
            url: `/service?${parsedQueryString}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.serviceList= data?.data;
                this.isLoading = false;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.serviceList = [];
              }
        )
    }

   
}