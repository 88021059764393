import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";

export class ServicesStore {    
    isLoading = false;
    servicesList = [];
    pageNo=1;
    pageSize=10;
    totalPages = null;
    serviceData = {};
    productsList = []
    constructor() {
      makeObservable(this, {
        getServicesList: action,
        isLoading: observable,
        servicesList: observable,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        serviceData: observable,
        getServices_onId: action,
        getProducts: action,
        productsList: observable
      });
    }

    getServicesList(currentPage, currentPageSize, fields, searchValue, status) {
        this.isLoading = true;
        let url;
        if (searchValue && status == undefined) {
          url = `/service?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&fields=${fields}`
        } else if (!searchValue && status != undefined) {
          url = `/service?pageNo=${currentPage}&pageSize=${currentPageSize}&isActive=${status}&fields=${fields}`
        } else if (searchValue && status != undefined) {
          url = `/service?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchValue}&isActive=${status}&fields=${fields}`
        } else {
          url = `/service?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=${fields}`
        }
        return io({
            url:url,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.servicesList = data?.data;
                    this.page = data?.metadata?.page;
                    this.totalPages = data?.metadata?.total;
                    this.isLoading = false;
                    return data?.data;
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.servicesList = [];
                this.page = 1;
                this.totalPages = 1;
                const { response: { data = {} } = {} } = error
                message.error(data.message);
            });
    }
    createService(fields, isAdd, serviceID, history){
        io({
          method: "post",
          url : isAdd ? `/service`: `/service/${serviceID}`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Successfully ${ isAdd ? "Added" : "updated"} Service`);
            history.push("/services")
          })
          .catch((error) => {
            console.log(error);
          });
      };
    getServices_onId( serviceID ) {
        this.isLoading = true;
        return io({
            url: `/service/${serviceID }`,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.serviceData = data;
                    this.isLoading = false;
                    return data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.serviceData = {};
            });
    }
    getProducts() {
        this.isLoading = true;
        return io({
            url: `product?fields=product_name&isActive=true`,
            method: "GET",
        })
            .then(
                action(({ data }) => {                    
                    this.productsList = data?.data;
                    this.isLoading = false;
                    return data
                })
            )
            .catch((error) => {
                this.isLoading = false;
                this.productsList = {};
            });
    }
    uploadServicesImage(fmData, config, onSuccess, onError, setImage_value, file) {
        io({
            url: "file",
            method: "POST",
            data: fmData, 
            config,
        }).then(({ data }) => {
            onSuccess(file);
            setImage_value(data?.url)
        }).catch(err => {
            message.error(err.response.message)
            const error = new Error('Some error');
            onError({event:error});
        })
    }

}