import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { observable, action, makeObservable } from "mobx";
import { getBaseUrl, io } from "../api/io";

export class CommonImagesStore {
  commonImagesList = [];
  isLoading = true;
  pictureUrl = "";

  constructor() {
    makeObservable(this, {
      getCommonImages: action,
      commonImagesList: observable,
      isLoading: observable,
      deleteRecord:action,
      pictureUrl: observable,
    });
  }

  getCommonImages(searchText) {
    return io({
      url: `/commonimages${searchText ? searchText : ""}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = false;
        this.commonImagesList = data?.data;
        return data?.data;
      })
      .catch((error) => {
        this.isLoading = false;
        this.commonImagesList = [];
      });
  }

  deleteRecord = (id) => {
    io({
      method: "delete",
      url: `/commonimages/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getCommonImages();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  createCommonImage(fields, isAdd, recordId, closeModal){
    io({
      method: isAdd ? "post" : "put",
      url : isAdd ? `/commonimages`: `/commonimages/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} Common Image`);
        this.getCommonImages();
        closeModal();
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        message.error(`${data?.codeName === undefined && data?.errmsg}`);
      });
  };

  uploadImage(options) {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { X_TENANT_ID: Cookies.get("x_tenant_id") },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    fmData.append("image", file);
    axios
      .post(`${getBaseUrl()}/iclap-api/file`, fmData, config)
      .then((res) => {
        onSuccess(file);
        message.success(res.data.msg);
        this.pictureUrl = res.data.url;
      })
      .catch((err) => {
        const error = new Error('Some error');
        this.pictureUrl = "";
        onError({event:error});
      });
  };

}
