import { message, notification } from "antd";
import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";

export class ProductsStore {
  products = [];
  isLoading = true;
  pageNo= 1;
  pageSize=10;
  totalPages = null;
  productDetails = "";
  productsList = []

  constructor() {
    makeObservable(this, {
      getProducts: action,
      products: observable,
      isLoading: observable,
      deleteRecord:action,
      createProduct: action,
      pageNo: observable,
      pageSize: observable,
      totalPages: observable,
      productDetails: observable,
      getProducts_forFilter: action,
      productsList: observable
    });
  }

  getProducts(currentPage, currentPageSize,fields, status, productName) { 
    this.isLoading = false;
    let url;
    if(status != undefined && productName == undefined){
      url = `/product?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=${fields}&isActive=${status}&archived=false`
    } else if(status == undefined && productName != undefined){
      url = `/product?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=${fields}&product_name=${productName}&archived=false`
    } else if(status != undefined && productName != undefined){
      url = `/product?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=${fields}&isActive=${status}&product_name=${productName}&archived=false`
    } else {
      url = `/product?pageNo=${currentPage}&pageSize=${currentPageSize}&fields=${fields}&archived=false`
    }
    return io({
      url: url,
      method: "GET",
    })
      .then(({ data }) => {
        this.isLoading = true;
        this.products = data?.data;
        this.page = data?.metadata?.page;
        this.totalPages = data?.metadata?.total;
        return data?.data;
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        console.log(error);
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
        this.isLoading = true;
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
      });
  }

  deleteRecord = (id) => {
    io({
      method: "delete",
      url: `/product/${id}`,
    })
      .then((data) => {
        message.success("Deleted successfully");
        this.getProducts(this.pageNo, this.pageSize);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  createProduct(fields, isAdd, recordId, history){
    io({
      method: "POST",
      url : isAdd ? `/product`: `/product/${recordId}`,
      data: fields,
    })
      .then(({ res }) => {
        message.success(`Successfully ${ isAdd ? "Added" : "updated"} product`);
        history.push("/products")
      })
      .catch((error) => {
        const { response: { data = {} } = {} } = error
        console.log(error);
        notification["error"]({
          description: data?.message,
          duration: 5,
        });
      });
  };

  getProductDetailsById(id) {
    return io({
      url: `/product/${id}`,
      method: "GET",
    })
      .then(({ data }) => {
        this.productDetails = data;
        return data;
      })
      .catch((error) => {
        this.productDetails = [];
      });
  }

  getProducts_forFilter() {
    // this.isLoading = true;
    return io({
        url: `product?fields=product_name,isActive&isActive=true&archived=false`,
        method: "GET",
    })
        .then(
            action(({ data }) => {                    
                this.productsList = data?.data;
                // this.isLoading = false;
                return data?.data
            })
        )
        .catch((error) => {
            // this.isLoading = false;
            this.productsList = [];
        });
}


}
