import { observable, action, makeObservable } from "mobx";
import { io } from "../api/io";
import { message } from "antd";
import { observer } from "mobx-react-lite";

export class QuestionsStore {    
    isLoading = false;
    questionsList = [];
    pageNo= 1;
    pageSize=10;
    totalPages = null;
    questionsDataonId= {};
    constructor() {
      makeObservable(this, {
        isLoading: observable,
        questionsList:observable,
        getServiceQuestionsList:action,
        pageNo: observable,
        pageSize: observable,
        totalPages: observable,
        questionsDataonId: observable,
        getQuestionsBasedOnId:action
      });
    }
    getServiceQuestionsList(currentPage, currentPageSize, searchText){   
        this.isLoading = true;
        return io({
            url: searchText ? `question?pageNo=${currentPage}&pageSize=${currentPageSize}&searchText=${searchText}`: `question?pageNo=${currentPage}&pageSize=${currentPageSize}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.questionsList= data?.data;
                this.page = data?.metadata?.page;
                this.totalPages = data?.metadata?.total;
                this.isLoading = false;
            })
        ).catch(
            (error) => {
                this.isLoading = false;
                this.questionsList = [];
                this.page = 1;
                this.totalPages = 1;
                const { response: { data = {} } = {} } = error
                message.error(data.message);
              }
        )
    }
    getQuestionsBasedOnId(question_id){
        this.isLoading = true;
        return io({
            url: `/question/${question_id}`,
            method: "GET",
        }).then(
            action(({data}) => {
                this.questionsDataonId = data
                this.isLoading = false;
                return data;
            })
        ).catch(
            () => {
                this.isLoading = false;
                this.questionsDataonId = {};
              }
        )
    }
    createQuestion(fields, isAdd, questionID){
        io({
            method: isAdd ? "post" : "put",
          url : isAdd ? `/question`: `/question/${questionID}`,
          data: fields,
        })
          .then(({ res }) => {
            message.success(`Successfully ${ isAdd ? "Created" : "Updated"} Question`);
          })
          .catch((error) => {
            console.log(error);
          });
    };
    deleteQuestion = (question_id,setPagination) => {
        io({
          method: "delete",
          url: `/question/${question_id}`,
        })
          .then((data) => {
            message.success("Deleted successfully");
            setPagination({current: 1, pageSize: 10, total: null})
            this.getServiceQuestionsList(this.pageNo, this.pageSize)
          })
          .catch((error) => {
            console.log(error);
          });
      };

}